import React, { PropsWithChildren, ReactNode } from 'react';

import { DEFAULT_MODAL_ARIA_LABELLEDBY } from '@/shared/constants';

import ButtonClose from './ButtonClose';
import { ModalBody, ModalDialog, ModalFooter, ModalHeader, ModalTitle } from './parts';

interface IProps {
  title: ReactNode;
  handlerClose: VoidFunction;
  footer?: ReactNode;
  className?: string;
  isFocusOnCloseButton?: boolean;
}
const ModalOne: React.FC<PropsWithChildren<IProps>> = ({
  title,
  handlerClose,
  children,
  footer,
  className = '',
  isFocusOnCloseButton,
}) => {
  return (
    <ModalDialog className={className}>
      <ModalHeader>
        <ModalTitle id={DEFAULT_MODAL_ARIA_LABELLEDBY}>{title}</ModalTitle>
        <ButtonClose handlerClose={handlerClose} isFocus={isFocusOnCloseButton} />
      </ModalHeader>

      <ModalBody>{children}</ModalBody>

      <ModalFooter>{footer}</ModalFooter>
    </ModalDialog>
  );
};
export default ModalOne;
