import ReactModal from 'react-modal';
import styled from 'styled-components';

import type { IDefaultTheme } from '@/shared/themes/types';

export const ModalCore = styled(ReactModal)`
  outline: none;
  max-width: 615px;
  height: fit-content;
  position: relative;
  top: 32px;
  margin: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 767px) {
    max-width: 345px;
  }
`;

export const ModalTitle = styled.h2`
  margin: 32px 32px 32px 22px !important;
  width: 100%;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(51 51 51 / 10%);
  background-color: #fff;
  font-size: 18px;
  color: #000;
  padding: 10px;
`;
export const ModalBody = styled.div`
  color: #000;
  padding: 0;
  border-top: 0px solid #000;
  border-bottom: 0px solid #000;

  position: relative;
  flex: 1 1 auto;
  display: block;
`;
export const ModalFooter = styled.div`
  color: #000;
  padding: 24px 32px;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: #efefef;
  gap: 16px;
`;
export const ModalDialog = styled.div`
  border-radius: 4px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.75);
  background-color: #f7f7f7;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
`;
export const ButtonCloseStyle = styled.button`
  box-sizing: content-box;
  width: 24px;
  height: 24px;
  font-size: 14px;
  padding: 0;
  color: ${({ theme, customColor }: { theme: IDefaultTheme; customColor?: string }) =>
    customColor || theme.defaultPrimaryColor};
  border: 0;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.01);
  position: absolute;
  right: 8px;
  top: 8px;
`;
