import React, { forwardRef, useState } from 'react';
import { useIntl } from 'react-intl';
import WrapperSelect, { GroupBase, Props } from 'react-select';

import { defaultStyles } from './part';
import type {
  ICustomOption,
  TCustomSelectForwardRef,
  TCustomSelectProps,
  TOnFocusAriaLiveMessagesHandler,
} from './types';

const Select = forwardRef<TCustomSelectForwardRef, TCustomSelectProps>(function CustomSelect(
  {
    styles = defaultStyles,
    className = 'select-wrapper-main',
    'aria-label': ariaLabel,
    'aria-required': ariaRequired = false,
    maxMenuHeight = 200,
    isClearable = false,
    blurInputOnSelect = false,

    onChange,
    callback,
    ...restProps
  },
  ref,
) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { formatMessage } = useIntl();

  const handlerChange: Props<ICustomOption, false, GroupBase<ICustomOption>>['onChange'] = (
    newValue,
  ) => {
    onChange(newValue);
    setMenuIsOpen(!menuIsOpen);

    // callback fires when component rerenders
    if (callback) requestAnimationFrame(callback);
  };

  const onFocus: TOnFocusAriaLiveMessagesHandler = (e): string => {
    return formatMessage({ id: 'GlobalComponents.Select.Text.AriaLive' }, { 0: e.focused.label });
  };

  const onClose = (): void => {
    setMenuIsOpen(false);
  };

  const onOpen = (): void => {
    setMenuIsOpen(true);
  };

  const onBlur: React.FocusEventHandler<HTMLInputElement> = () => {
    setMenuIsOpen(false);
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    switch (event.key) {
      case 'Escape':
        if (menuIsOpen) {
          event.stopPropagation();
        }
        break;
      case ' ':
        event.preventDefault();
        break;
      case 'Enter':
        if (!menuIsOpen) {
          setMenuIsOpen(true);
        } else {
          setMenuIsOpen(false);
        }
        break;
      default:
        break;
    }
  };

  return (
    <WrapperSelect
      {...restProps}
      ref={ref}
      styles={styles}
      className={className}
      aria-label={ariaLabel}
      aria-required={ariaRequired}
      maxMenuHeight={maxMenuHeight}
      isClearable={isClearable}
      blurInputOnSelect={blurInputOnSelect}
      // The properties below cannot be overridden, they are excluded from ICustomSelectProps typing
      menuIsOpen={menuIsOpen}
      onMenuClose={onClose}
      onMenuOpen={onOpen}
      onBlur={onBlur}
      onChange={handlerChange}
      onKeyDown={onKeyDown}
      ariaLiveMessages={{
        onFocus,
      }}
    />
  );
});

export default Select;
