import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Button } from '@/shared/components/Button';
import { DEFAULT_CURRENCY } from '@/shared/constants';
import { srSpeak } from '@/shared/utils/srSpeak';

import { InputHidden, Wrapper } from '../parts';
import type { TModifier } from '../types';

interface IProps {
  modifier: TModifier;
  parenthandler: (modifier: TModifier, callback?: VoidFunction) => void;
}

export const SelectOneModifier: React.FC<IProps> = ({ modifier, parenthandler }) => {
  const { register } = useFormContext();
  const { formatNumber, formatMessage } = useIntl();
  const messages = {
    select: formatMessage({ id: 'GlobalComponents.Modifiers.SelectOne.AriaLive.IsSelect' }),
  };
  const variants = modifier?.Variants;

  const handler = (idVariant: string): void => {
    const newVariants =
      variants?.map((item) =>
        item.VariantId === idVariant ? { ...item, IsChecked: true } : { ...item, IsChecked: false },
      ) || null;

    const result = { ...modifier, SelectedVariant: idVariant, Variants: newVariants };

    parenthandler(result);
  };
  useEffect(() => {
    if (modifier.SelectedVariant) {
      handler(modifier.SelectedVariant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!variants) return null;

  const input = !modifier.SelectedVariant && (
    <InputHidden
      {...register(modifier.ModifierId, { required: true })}
      tabIndex={-1}
      defaultValue={modifier.SelectedVariant || undefined}
    />
  );
  const list = variants.map((item) => {
    let checked = item.IsChecked;
    if (item.VariantId === modifier.SelectedVariant) checked = true;
    return (
      <Button
        key={item.VariantId}
        margin="8px 8px 0 0"
        width="max-content"
        padding="0 16px"
        isDark={checked}
        fontWeight="normal"
        click={() => {
          handler(item.VariantId);
          srSpeak(`${item.VariantName} ${!checked ? messages.select : ''}`);
        }}
      >
        {item.VariantName}{' '}
        {!item.HidePrice && (
          <span>
            ({formatNumber(item.VariantPrice, { style: 'currency', currency: DEFAULT_CURRENCY })})
          </span>
        )}
      </Button>
    );
  });

  return (
    <Wrapper aria-label={modifier.DisplayName}>
      {input}
      <div>{list}</div>
    </Wrapper>
  );
};
