import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toQuery, useMediaQuery } from 'react-responsive';

import { Modifiers } from '@/shared/components/Modifires';
import { useCurrencyFormmater } from '@/shared/hooks/currencyFormmater';

import {
  MainCell,
  MainCellHeader,
  MainCellModifiers,
  MainCellName,
  PriceCell,
  PriceCellHeader,
  QtyCell,
  QtyCellHeader,
  SummaryTable,
  SummaryTableFooterRow,
  SummaryTableHeaderRow,
  SummaryTableRow,
  TotalRowName,
  TotalRowSum,
} from './parts';
import type { IBasketProps } from './types';

const ContentOrderSummary: React.FC<
  IBasketProps & {
    isPaymentDetailsAvailable: boolean;
  }
> = ({
  SubTotal,
  TaxTotal,
  Total,
  ItemTotal,
  PromotionsTotal,
  RewardsTotal,
  HandlingFee,
  FulfillmentTotal,
  LineItems,
  isPaymentDetailsAvailable,
}) => {
  const { formatMessage } = useIntl();
  const format = useCurrencyFormmater();

  const messages = {
    qty: formatMessage({ id: 'qty' }),
    item: formatMessage({ id: 'item' }),
    price: formatMessage({ id: 'price' }),
    subtotal: formatMessage({ id: 'subtotal' }),
    tax: formatMessage({ id: 'tax' }),
    orderTotal: formatMessage({ id: 'orderTotal' }),
    promotions: formatMessage({ id: 'promotions' }),
    handlingFee: formatMessage({ id: 'handlingFee' }),
    fulfillmentTotal: formatMessage({ id: 'fulfillmentTotal' }),
    rewardsTotal: formatMessage({ id: 'Loyalty.Labels.RewardsTotal' }),
    itemTotalKey: formatMessage({ id: 'itemTotal' }),
  };

  const isMobile = useMediaQuery({
    query: toQuery({
      maxWidth: 767,
    }),
  });

  const QtyColHead = useRef<HTMLTableCellElement>(null);
  const MainColHead = useRef<HTMLTableCellElement>(null);
  const FooterNameCell = useRef<HTMLTableCellElement>(null);

  const [modifiersWidth, setModifiersWidth] = useState(0);
  useEffect(() => {
    const { font } = getComputedStyle(document.body);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return;
    context.font = font;
    context.font += ' 16px';
    const maxTextWidth = Math.max(
      ...[messages.subtotal, messages.promotions, messages.tax, messages.orderTotal].map(
        (text) => context.measureText(text).width,
      ),
    );
    const qtyComputedWidth: number =
      (QtyColHead.current &&
        parseInt(window.getComputedStyle(QtyColHead.current).getPropertyValue('width'), 10)) ||
      0;

    const footerNameComputedWidth: number =
      (FooterNameCell.current &&
        parseInt(window.getComputedStyle(FooterNameCell.current).getPropertyValue('width'), 10)) ||
      0;

    const mainCellCalculatedWidth: number =
      (MainColHead.current &&
        parseInt(window.getComputedStyle(MainColHead.current).getPropertyValue('width'), 10)) ||
      0;

    if (isMobile)
      setModifiersWidth(footerNameComputedWidth - qtyComputedWidth || mainCellCalculatedWidth);
    else setModifiersWidth(footerNameComputedWidth - qtyComputedWidth - maxTextWidth);
  }, [
    isMobile,
    FooterNameCell,
    QtyColHead,
    MainColHead,
    messages.subtotal,
    messages.promotions,
    messages.tax,
    messages.orderTotal,
  ]);

  const bodyList =
    LineItems && LineItems.length
      ? LineItems.map((_item) => {
          const itemPrice = format(_item.ListPrice);
          const itemTotal = format(_item.OriginalPrice * _item.Quantity);
          const itemDiscount =
            _item.TotalDiscountAmount && _item.TotalDiscountAmount !== 0
              ? format(_item.TotalDiscountAmount)
              : '';
          return (
            <SummaryTableRow key={_item.Id} style={{ background: 'transparent' }}>
              <QtyCell scope="row">{_item.Quantity}</QtyCell>
              <MainCell scope="row">
                <MainCellName>{`${_item.ProductName} (${itemPrice})`}</MainCellName>
                <MainCellModifiers width={modifiersWidth}>
                  {!!_item.ModifierItems && !!_item.ModifierItems.length && (
                    <Modifiers modifiers={_item.ModifierItems} />
                  )}
                </MainCellModifiers>
              </MainCell>
              <PriceCell>
                {!!itemDiscount && <div style={{ color: 'red' }}>{`-${itemDiscount}`}</div>}
                {itemTotal}
              </PriceCell>
            </SummaryTableRow>
          );
        })
      : null;

  return (
    <SummaryTable>
      {isPaymentDetailsAvailable ? (
        <>
          <thead>
            <SummaryTableHeaderRow>
              <QtyCellHeader
                ref={QtyColHead}
                scope="col"
                dangerouslySetInnerHTML={{
                  __html: messages.qty,
                }}
              />
              <MainCellHeader
                ref={MainColHead}
                scope="col"
                dangerouslySetInnerHTML={{
                  __html: messages.item,
                }}
              />
              <PriceCellHeader
                scope="col"
                dangerouslySetInnerHTML={{
                  __html: messages.price,
                }}
              />
            </SummaryTableHeaderRow>
          </thead>
          <tbody>{bodyList}</tbody>
          <tfoot>
            <SummaryTableFooterRow key="itemTotal">
              <TotalRowName
                scope="colgroup"
                colSpan={2}
                dangerouslySetInnerHTML={{
                  __html: messages.itemTotalKey,
                }}
              />
              <TotalRowSum>{format(ItemTotal as number)}</TotalRowSum>
            </SummaryTableFooterRow>
            {PromotionsTotal !== null && PromotionsTotal !== 0 && (
              <SummaryTableFooterRow key="promotions">
                <TotalRowName
                  scope="colgroup"
                  colSpan={2}
                  dangerouslySetInnerHTML={{
                    __html: messages.promotions,
                  }}
                />
                <TotalRowSum isDiscount>{`-${format(PromotionsTotal as number)}`}</TotalRowSum>
              </SummaryTableFooterRow>
            )}
            {RewardsTotal !== null && RewardsTotal !== 0 && (
              <SummaryTableFooterRow key="rewards">
                <TotalRowName
                  scope="colgroup"
                  colSpan={2}
                  dangerouslySetInnerHTML={{
                    __html: messages.rewardsTotal,
                  }}
                />
                <TotalRowSum isDiscount>{format(RewardsTotal as number)}</TotalRowSum>
              </SummaryTableFooterRow>
            )}
            <SummaryTableFooterRow key="subtotal">
              <TotalRowName
                scope="colgroup"
                colSpan={2}
                dangerouslySetInnerHTML={{
                  __html: messages.subtotal,
                }}
              />
              <TotalRowSum>{format(SubTotal as number)}</TotalRowSum>
            </SummaryTableFooterRow>
            {!!HandlingFee && HandlingFee !== 0 && (
              <SummaryTableFooterRow key="handlingFee">
                <TotalRowName
                  scope="colgroup"
                  colSpan={2}
                  dangerouslySetInnerHTML={{
                    __html: messages.handlingFee,
                  }}
                />
                <TotalRowSum>{format(HandlingFee as number)}</TotalRowSum>
              </SummaryTableFooterRow>
            )}
            {!!FulfillmentTotal && FulfillmentTotal !== 0 && (
              <SummaryTableFooterRow key="fulfillmentTotal">
                <TotalRowName
                  scope="colgroup"
                  colSpan={2}
                  dangerouslySetInnerHTML={{
                    __html: messages.fulfillmentTotal,
                  }}
                />
                <TotalRowSum>{format(FulfillmentTotal as number)}</TotalRowSum>
              </SummaryTableFooterRow>
            )}
            <SummaryTableFooterRow key="tax">
              <TotalRowName
                scope="colgroup"
                colSpan={2}
                dangerouslySetInnerHTML={{
                  __html: messages.tax,
                }}
              />
              <TotalRowSum>{format(TaxTotal as number)}</TotalRowSum>
            </SummaryTableFooterRow>
            <SummaryTableFooterRow key="total">
              <TotalRowName
                ref={FooterNameCell}
                scope="colgroup"
                colSpan={2}
                dangerouslySetInnerHTML={{
                  __html: messages.orderTotal,
                }}
              />
              <TotalRowSum>{format(Total as number)}</TotalRowSum>
            </SummaryTableFooterRow>
          </tfoot>
        </>
      ) : (
        <span style={{ color: '#a94442' }}>
          <FormattedMessage id="Errors.Checkout.MobilePayment.Text" />
        </span>
      )}
    </SummaryTable>
  );
};

export default ContentOrderSummary;
