import React, { PropsWithChildren } from 'react';

import { ButtonComponent } from '../parts';
import type { IButton } from '../types';

interface IProps
  extends Pick<
    IButton,
    | 'width'
    | 'height'
    | 'margin'
    | 'padding'
    | 'isDark'
    | 'borderRadius'
    | 'fontSize'
    | 'fontWeight'
    | 'className'
  > {
  click?: VoidFunction;
  title?: string;
  disabled?: boolean;
}
const Button: React.FC<PropsWithChildren<IProps>> = ({
  click = () => {},
  children,
  width,
  height,
  margin,
  padding,
  fontSize,
  fontWeight,
  isDark,
  disabled,
  borderRadius,
  className,
  title,
}) => {
  return (
    <ButtonComponent
      className={className || 'ButtonDefault'}
      onClick={click}
      width={width}
      height={height}
      margin={margin}
      fontSize={fontSize}
      fontWeight={fontWeight}
      isDark={isDark}
      disabled={disabled}
      padding={padding}
      borderRadius={borderRadius}
      title={title}
    >
      {children}
    </ButtonComponent>
  );
};
export default Button;
