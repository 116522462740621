import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { GroupBase, InputProps, components } from 'react-select';
import styled from 'styled-components';

import { ErrorMessage } from '@/shared/components/ErrorMessage';
import type { TError } from '@/shared/components/ErrorMessage/types';
import { RequiredField } from '@/shared/components/Required';
import { Select } from '@/shared/components/Select';
import type { ICustomOption } from '@/shared/components/Select/types';

import { ORDER_AHEAD_RULES } from '../constants';
import { BlockStyle, DescriptionStyle, LocationOne } from '../parts';
import type { TOrderAheadValuesForm } from '../types';
import { Times } from './Times';

const Wrapper = styled.div`
  display: block;
`;

const Input: React.FC<InputProps<ICustomOption, false, GroupBase<ICustomOption>>> = (props) => {
  return <components.Input {...props} aria-describedby="location-error" aria-required />;
};

interface IProps {
  locations: {
    label: string;
    value: string;
  }[];
  locationOne: string;
  times: {
    value: string;
    title: string;
  }[];
  isSlotExpired?: boolean;
  loading: boolean;
}
export const PickUpPart: React.FC<IProps> = ({
  locations,
  locationOne,
  times,
  isSlotExpired = false,
  loading,
}) => {
  const { control, setFocus } = useFormContext<TOrderAheadValuesForm>();
  const { formatMessage } = useIntl();
  const messages = {
    pickUpLocation: formatMessage({ id: 'PickUpLocation' }),
    pickUpTime: formatMessage({ id: 'PickUpTime' }),
    NoPickupTimeAvailable: formatMessage({ id: 'NoPickupTimeAvailable' }),
  };

  const rules = ORDER_AHEAD_RULES.LOCATION(messages.pickUpLocation);

  return (
    <>
      {!locations.length || (
        <Wrapper>
          <DescriptionStyle>
            <div
              style={{ display: 'inline' }}
              dangerouslySetInnerHTML={{
                __html: messages.pickUpLocation.toUpperCase(),
              }}
            />{' '}
            <RequiredField />
          </DescriptionStyle>
          <BlockStyle mb="16px">
            {locationOne ? (
              <LocationOne>{locationOne}</LocationOne>
            ) : (
              <Controller
                control={control}
                name="location"
                // @ts-expect-error: not improving the typing of the external library
                rules={rules}
                onChange={() => setFocus('location')}
                render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => {
                  const valueSelected =
                    locations.find((location) => location.value === value) || null;

                  return (
                    <>
                      <Select
                        options={locations}
                        value={valueSelected}
                        onChange={(option) => onChange(option?.value)}
                        aria-label={messages.pickUpLocation}
                        aria-required
                        name={name}
                        ref={ref}
                        components={{ Input }}
                        callback={() => setFocus('location')}
                      />
                      <ErrorMessage id={`${name}-error`} error={error as TError} />
                    </>
                  );
                }}
              />
            )}
          </BlockStyle>
        </Wrapper>
      )}

      <Times
        title={messages.pickUpTime}
        times={times}
        textNoTimes={messages.NoPickupTimeAvailable}
        isSlotExpired={isSlotExpired}
        loading={loading}
      />
    </>
  );
};
