import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { toQuery, useMediaQuery } from 'react-responsive';

import { ButtonAddToFavorites } from '@/shared/components/Button';
import { ProductAllergens, ProductImg } from '@/shared/components/Img';
import { isPropsEqual } from '@/shared/utils/props-comparison';
import { hasValue } from '@/shared/utils/validation';

import { OrderAgainButton } from './blocks/OrderAgainButton';
import {
  FooterItem,
  HeaderItemBtn,
  HeaderItemName,
  HeaderItemNameLink,
  ItemCalories,
  ItemContent,
  ItemDetails,
  ItemInfo,
  ItemPrice,
  LowerPart,
  ProductCardComponent,
  UpperPart,
} from './parts';
import type { TProductCard } from './types';

const ProductCard: React.FC<TProductCard> = ({
  product: { MenuProductId, ProductId, IsTopPurchased, IsDeemphasized, Product },
  model,
  context: {
    isProductInTotalMeal,
    isFavorite,
    handleOpenFoodProductDetails,
    handleAddToFavorites,
    renderButtonMealCalculator,
    margin,
  },
}) => {
  const isMobile = useMediaQuery({
    query: toQuery({
      maxWidth: 767,
    }),
  });

  const { formatMessage } = useIntl();

  const messages = {
    viewProductDetailsTitle: formatMessage({ id: 'ViewProductDetailsTitle' }),
    calories: formatMessage({ id: 'Calories' }),
  };

  const isProductCalories = !model.HideProductsCalorie && hasValue(Product?.Calories);

  const isOpenNutritionalInformation =
    !model.HideProductsNutritional &&
    !Product?.IsBulkProduct &&
    Product?.HasNutritionalInformation &&
    isProductCalories;

  const isOpenModalProductDetails = model.IsFoodOrder || isOpenNutritionalInformation;

  const isButtonMealCalculatorForCard =
    !!renderButtonMealCalculator && model.ShowAddToCalculatorButton;

  const isProductPrice =
    hasValue(Product?.CurrentPriceCulture) && !!Product?.CurrentPrice && Product.CurrentPrice > 0;

  const isProductPriceForCard = (model.DisplayPriceOnTheMenu || !model.IsDaily) && isProductPrice;

  const isProductInfo = isButtonMealCalculatorForCard || isProductCalories || isProductPriceForCard;

  const isProductDietaryInformation = Product?.DietaryInformation?.some(
    (d) => d.IsEnabled && d.IconUrl,
  );

  const isProductFooter = !model.HideEatWellIconsFromMenu && isProductDietaryInformation;

  const productName = Product?.MarketingName || Product?.DisplayName;

  const buttonAddToFavorites = model.UserFavoritiesEnabled ? (
    <ButtonAddToFavorites
      id={MenuProductId || ProductId}
      handlerClick={handleAddToFavorites}
      marketingName={productName}
      IsUserFavorite={isFavorite}
      data-testid="product-card-footer-button-add-to-favorites"
    />
  ) : null;

  const description = !model.HideProductsShortDescription
    ? Product.Description || Product.ShortDescription
    : null;
  const isColumnForDescription =
    !isMobile &&
    !!description &&
    !IsDeemphasized &&
    (model.DisplayPriceOnTheMenu || !model.HideProductsCalorie);

  const isShowOrderAgainButton = IsTopPurchased && model.IsFoodOrder;

  const isPadding =
    IsDeemphasized ||
    !(isProductInfo || description || isProductDietaryInformation || buttonAddToFavorites);

  const OrderAgainButtonElement = isShowOrderAgainButton ? (
    <OrderAgainButton handlerClick={handleOpenFoodProductDetails} />
  ) : null;
  return (
    <ProductCardComponent
      data-testid="product-card"
      activeBorder={isProductInTotalMeal}
      className="ProductCard"
      id={MenuProductId || ProductId}
      margin={margin}
      role="listitem"
      isPadding={isPadding}
    >
      <UpperPart>
        <HeaderItemBtn
          data-testid="product-card-header"
          className="HeaderItem"
          isHigh={model.DisplayProductImages && !IsDeemphasized}
          click={isOpenModalProductDetails ? handleOpenFoodProductDetails : undefined}
          disabled={!isOpenModalProductDetails}
          title={isOpenModalProductDetails ? messages.viewProductDetailsTitle : undefined}
        >
          <ProductImg
            data-testid="product-card-header-img"
            src={Product?.ImageUrl}
            alt={productName}
            isShow={model.DisplayProductImages}
          />

          <HeaderItemName className="HeaderItemName" data-testid="product-card-header-title">
            {isOpenModalProductDetails ? (
              <HeaderItemNameLink
                className="HeaderItemNameLink"
                data-testid="product-card-header-link"
              >
                {productName}
              </HeaderItemNameLink>
            ) : (
              productName
            )}
          </HeaderItemName>
        </HeaderItemBtn>
        {(isProductInfo || isColumnForDescription) && (
          <ItemInfo
            data-testid="product-card-info-block"
            className="ItemInfo"
            IsDeemphasized={IsDeemphasized}
            isDescription={isColumnForDescription}
          >
            {isButtonMealCalculatorForCard && renderButtonMealCalculator}
            {isProductCalories && (
              <ItemCalories
                data-testid="product-card-info-block-calories"
                className="ItemCalories"
                dangerouslySetInnerHTML={{
                  __html: `${Product.Calories} ${messages.calories}`,
                }}
              />
            )}
            {isProductPriceForCard && (
              <ItemPrice data-testid="product-card-info-block-price" className="ItemPrice">
                {Product.CurrentPriceCulture}
              </ItemPrice>
            )}
          </ItemInfo>
        )}
        {!IsDeemphasized && description && (
          <ItemContent data-testid="product-card-description" className="ItemContent">
            {description}
          </ItemContent>
        )}
      </UpperPart>
      <LowerPart>
        {!IsDeemphasized ? (
          <>
            {(isProductFooter || buttonAddToFavorites) && (
              <FooterItem className="FooterItem" data-testid="product-card-footer">
                {isProductDietaryInformation && (
                  <ItemDetails className="ItemDetails">
                    {(!IsTopPurchased || !model.IsFoodOrder) && (
                      <ProductAllergens
                        dietaryInformation={Product?.DietaryInformation}
                        data-testid="product-card-footer-allergens"
                      />
                    )}
                  </ItemDetails>
                )}
                {OrderAgainButtonElement}
                {buttonAddToFavorites}
              </FooterItem>
            )}
            {!isProductFooter && !buttonAddToFavorites && (
              <FooterItem className="FooterItem" data-testid="product-card-footer">
                {OrderAgainButtonElement}
              </FooterItem>
            )}
          </>
        ) : (
          <FooterItem className="FooterItem" data-testid="product-card-footer">
            {OrderAgainButtonElement}
          </FooterItem>
        )}
      </LowerPart>
    </ProductCardComponent>
  );
};

export default memo(ProductCard, isPropsEqual);
