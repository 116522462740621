import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { srSpeak } from '@/shared/utils/srSpeak';

import { Input, Wrapper } from '../parts';
import type { TModifier } from '../types';

interface IProps {
  modifier: TModifier;
  parenthandler: (modifier: TModifier, callback?: VoidFunction) => void;
}

export const TextModifier: React.FC<IProps> = ({ modifier, parenthandler }) => {
  const { register } = useFormContext();

  const { formatMessage } = useIntl();
  const messages = {
    enter: formatMessage({ id: 'GlobalComponents.Modifiers.Text.AriaLive.Enter' }),
    cleared: formatMessage({ id: 'GlobalComponents.Modifiers.Text.AriaLive.Cleared' }),
  };
  const onBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const result = { ...modifier, SelectedVariant: e.target.value };
    parenthandler(result);
    srSpeak(
      `${modifier.DisplayName} ${!modifier?.SelectedVariant ? messages.enter : messages.cleared}`,
    );
  };
  if (!modifier) return null;
  return (
    <Wrapper>
      <Input
        {...register(modifier.ModifierId, {
          required: modifier.IsTextRequired,
          onBlur,
          value: modifier?.SelectedVariant,
        })}
      />
    </Wrapper>
  );
};
