import React from 'react';
import { useIntl } from 'react-intl';

import { Required } from '@/shared/components/Required';
import { Underline } from '@/shared/components/Underline';

import { ModalTitle, WarningContainer, WarningDiv, WrapRequired } from '../parts';
import type { TProductDataForCart } from '../types';
import { Modifiers } from './Modifiers';
import { TextArea } from './TextArea';

interface IProps {
  warningMsg?: string;
  modifiers?: TProductDataForCart['Product']['Modifiers'];
  setDataForCart: React.Dispatch<React.SetStateAction<TProductDataForCart>>;
  isShowSpecialInstructions: boolean;
  recipientInstructions?: string;
  menuCalendar?: {
    AvailableDatesFoodOrder?: string[];
    NumberDaysAfter?: number;
  };
}

export const Order: React.FC<IProps> = ({
  warningMsg,
  modifiers,
  setDataForCart,
  isShowSpecialInstructions,
  recipientInstructions,
  menuCalendar,
}) => {
  const { formatMessage } = useIntl();

  const messages = {
    specialInstructions: formatMessage({ id: 'SpecialInstructions' }),
    placeholderTextArea: formatMessage({ id: 'PlaceholderTextArea' }),
    required: formatMessage({ id: 'RequiredFields' }),
  };
  const handlerRecipientInstructions = (text: string): void => {
    setDataForCart((dataForCart) => {
      return {
        ...dataForCart,
        RecipientInstructions: text,
      };
    });
  };
  return (
    <>
      <WarningDiv isShow={!!warningMsg}>
        <svg className="svg help-circle" viewBox="0 0 24 24" role="img">
          <path
            d="M14.299995,21.399963L14.299995,24.399963 17.699997,24.399963 17.699997,21.399963z M14.799995,6.6000061L14.299995,17.5 17.699997,17.5 17.199997,6.6000061z M16,0C24.799995,0 32,7.1999817 32,16 32,24.799988 24.799995,32 16,32 7.1999969,32 0,24.799988 0,16 0,7.1999817 7.1999969,0 16,0z"
            transform="scale(0.75,0.75)"
            fill="white"
          />
        </svg>
        <WarningContainer
          dangerouslySetInnerHTML={{
            __html: warningMsg || '',
          }}
        />
      </WarningDiv>

      <WrapRequired>
        <Required />
        <span
          dangerouslySetInnerHTML={{
            __html: messages.required,
          }}
        />
      </WrapRequired>
      {modifiers && modifiers?.length > 0 && (
        <>
          <Modifiers modifiers={modifiers} setData={setDataForCart} menuCalendar={menuCalendar} />
          <Underline />
        </>
      )}
      {isShowSpecialInstructions && (
        <>
          <ModalTitle
            dangerouslySetInnerHTML={{
              __html: messages.specialInstructions,
            }}
          />
          <TextArea
            text={recipientInstructions}
            handler={handlerRecipientInstructions}
            ariaLabel={messages.specialInstructions}
            placeholder={messages.placeholderTextArea}
          />
        </>
      )}
    </>
  );
};
