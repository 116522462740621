import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ButtonCloseStyle } from './parts';

interface IProps {
  handlerClose: VoidFunction;
  style?: React.CSSProperties;
  isFocus?: boolean;
}
const ButtonClose: React.FC<IProps> = ({ handlerClose, style, isFocus }) => {
  const { formatMessage } = useIntl();
  const messages = {
    ariaLabel: formatMessage({ id: 'Modals.Buttons.Close.AriaLabel' }),
  };

  const buttonlRef = React.useRef<HTMLButtonElement>(null);
  const setFocus = (): void => {
    buttonlRef.current?.focus();
  };

  useEffect(() => {
    if (isFocus) setFocus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ButtonCloseStyle
      style={style}
      aria-label={messages.ariaLabel}
      onClick={handlerClose}
      ref={buttonlRef}
    >
      &#10006;
    </ButtonCloseStyle>
  );
};
export default ButtonClose;
