import { PREFIX_URL_API } from '@/shared/constants';
import type { TModelMenu } from '@/shared/types/menu';
import { apiClient } from '@/shared/utils/apiClient';

import { createAsyncThunk } from '@reduxjs/toolkit';

import type {
  IApiPropsFetchMenu,
  IApiResponseAddFoodProductToCart,
  IApiResponseAddOrRemoveFavoriteProduct,
  IApiResponseFetchMenu,
  IApiResponseFoodProductDetails,
  IApiResponseGetDataForMealCalculator,
  IApiResponseGetMenuCalendar,
  IApiResponseGetMenuPeriods,
  IApiResponseGetPickUPTimes,
  TApiAddFoodProductToCart,
  TApiAddOrRemoveFavoriteProduct,
  TApiFetchFoodProductDetails,
  TApiGetDataForMealCalculator,
  TApiGetMenuCalendar,
  TApiGetMenuPeriods,
  TApiGetPickUPTimes,
  TApiSaveFilters,
} from './types';

export const fetchMenu = createAsyncThunk<IApiResponseFetchMenu, IApiPropsFetchMenu>(
  'menus/fetchMenus',
  async ({ mode, customPeriodId, customDate, time, fulfillmentMode, callback }, thunkAPI) => {
    const { locationId, date, periodId, storeIds } = (thunkAPI.getState() as MenuRootState).menu
      .requestParameters;

    try {
      const url = `${PREFIX_URL_API.MENU}/GetMenus?locationId=${locationId}&storeIds=${
        storeIds || ''
      }&mode=${mode}&date=${customDate || date}&time=${time || ''}&periodId=${
        customPeriodId || periodId
      }&fulfillmentMethod=${fulfillmentMode !== undefined ? fulfillmentMode : ''}` as string;
      // TODO: сравнить типизации параметров на бэке
      const response = await apiClient.get<TModelMenu>(url);
      if (callback) callback();
      document.dispatchEvent(
        new CustomEvent('menuModeChanged', {
          detail: {
            mode,
            locationId,
            storeIds,
            date: customDate || date,
            periodId: customPeriodId || periodId,
          },
        }),
      );
      return { menu: response, mode };
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  },
);

export const getDataForMealCalculator: TApiGetDataForMealCalculator = (totalMealIds) => {
  const url = `${PREFIX_URL_API.PRODUCT}/GetMenuProductsByIdsJson?ids=${totalMealIds}`;
  return apiClient.get<IApiResponseGetDataForMealCalculator>(url);
};

export const fetchFoodProductDetails: TApiFetchFoodProductDetails = ({
  locationId,
  mode,
  date,
  periodId,
  prod,
  root,
}) => {
  const url = `${PREFIX_URL_API.PRODUCT}/FoodProductDetailsJson?locationId=${
    locationId || ''
  }&mode=${mode || ''}&date=${date || ''}&periodId=${periodId || ''}&prod=${prod || ''}&root=${
    root || ''
  }`;

  return apiClient.get<IApiResponseFoodProductDetails>(url);
};

export const addOrRemoveFavoriteProduct: TApiAddOrRemoveFavoriteProduct = (payload) => {
  const url = `${PREFIX_URL_API.PRODUCT}/AddToFavoritePost`;
  return apiClient.post<IApiResponseAddOrRemoveFavoriteProduct>(url, payload);
};

export const addFoodProductToCart: TApiAddFoodProductToCart = (payload) => {
  const url = `${PREFIX_URL_API.PRODUCT}/AddFoodProductToCartJson`;
  return apiClient.post<IApiResponseAddFoodProductToCart>(url, payload);
};

export const getMenuCalendar: TApiGetMenuCalendar = ({ locationId, storeIds }) => {
  const url = `${PREFIX_URL_API.API}/menus/GetMenuCalendar?locationId=${locationId}&storeIds=${storeIds}`;
  return apiClient.get<IApiResponseGetMenuCalendar>(url);
};

export const getMenuPeriods: TApiGetMenuPeriods = ({ locationId, storeId, date, mode }) => {
  const url = `${PREFIX_URL_API.MENU}/GetMenuPeriods?locationId=${locationId || ''}&storeId=${
    storeId || ''
  }&date=${date || ''}&mode=${mode || ''}`;
  return apiClient.get<IApiResponseGetMenuPeriods>(url);
};

export const getPickUPTimes: TApiGetPickUPTimes = ({
  locationId,
  storeIds,
  date,
  mode,
  periodId,
  fulfillment,
  pageStoreId,
}) => {
  const url = `${PREFIX_URL_API.MENU}/GetPickUPTimes?locationId=${locationId || ''}&mode=${
    mode || ''
  }&date=${date || ''}&periodId=${periodId || ''}&FulfillmentMethod=${fulfillment}&pageStoreId=${
    pageStoreId || ''
  }&storeIds=${storeIds || ''}`;
  return apiClient.get<IApiResponseGetPickUPTimes>(url);
};

export const apiSaveFilters: TApiSaveFilters = ({ filterItems }) => {
  const url = `${PREFIX_URL_API.MENU}/SaveFilter`;
  return apiClient.post<void>(url, { filterItems });
};
