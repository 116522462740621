import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ErrorMessage } from '@/shared/components/ErrorMessage';
import type { TError } from '@/shared/components/ErrorMessage/types';
import { RequiredField } from '@/shared/components/Required';

import { ORDER_AHEAD_RULES } from '../constants';
import { BlockStyle, DescriptionStyle, TextAreaStyle } from '../parts';
import type { TOrderAheadValuesForm } from '../types';
import { Times } from './Times';

const Wrapper = styled.div`
  display: block;
`;
interface IProps {
  times: {
    value: string;
    title: string;
  }[];
  isSlotExpired?: boolean;
  loading: boolean;
}
export const DeliveryPart: React.FC<IProps> = ({ times, isSlotExpired = false, loading }) => {
  const { control } = useFormContext<TOrderAheadValuesForm>();
  const { formatMessage } = useIntl();

  const messages = {
    deliveryLocation: formatMessage({ id: 'DeliveryLocation' }),
    deliveryPlaceholder: formatMessage({
      id: 'GlobalComponents.Modal.OrderAhead.Delivery.Location.InputPlaceholder',
    }),
    deliveryTime: formatMessage({ id: 'DeliveryTime' }),
    NoDeliveryTimeAvailable: formatMessage({ id: 'NoDeliveryTimeAvailable' }),
  };

  const rules = ORDER_AHEAD_RULES.DELIVERY_LOCATION(messages.deliveryLocation);

  return (
    <>
      <Wrapper aria-required="true">
        <DescriptionStyle>
          <div
            style={{ display: 'inline' }}
            dangerouslySetInnerHTML={{
              __html: messages.deliveryLocation.toUpperCase(),
            }}
          />{' '}
          <RequiredField />
        </DescriptionStyle>
        <BlockStyle>
          <Controller
            control={control}
            name="deliveryLocation"
            // @ts-expect-error: not improving the typing of the external library
            rules={rules}
            render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
              <>
                <TextAreaStyle
                  aria-label={messages.deliveryLocation}
                  placeholder={messages.deliveryPlaceholder}
                  value={value}
                  onChange={({ target }) => onChange(target.value)}
                  aria-describedby={`${name}-error`}
                  aria-required
                  name={name}
                  ref={ref}
                />
                <ErrorMessage id={`${name}-error`} error={error as TError} />
              </>
            )}
          />
        </BlockStyle>
      </Wrapper>
      <Times
        title={messages.deliveryTime}
        times={times}
        textNoTimes={messages.NoDeliveryTimeAvailable}
        isSlotExpired={isSlotExpired}
        loading={loading}
      />
    </>
  );
};
