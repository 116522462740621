import React, { FC, PropsWithChildren } from 'react';

import { ButtonSecondStyled } from '../parts';
import type { IButton } from '../types';

interface IProps
  extends Pick<
    IButton,
    | 'width'
    | 'height'
    | 'margin'
    | 'padding'
    | 'isDark'
    | 'borderRadius'
    | 'fontWeight'
    | 'aria-label'
    | 'className'
  > {
  click?: VoidFunction;
  title?: string;
  disabled?: boolean;
}

const ButtonSecond: FC<PropsWithChildren<IProps>> = ({
  click,
  width,
  height,
  margin,
  padding,
  title,
  isDark,
  disabled,
  children,
  borderRadius,
  fontWeight,
  'aria-label': ariaLabel,
  className = 'ButtonSecond',
}) => {
  return (
    <ButtonSecondStyled
      className={className}
      onClick={click}
      width={width}
      height={height}
      margin={margin}
      isDark={isDark}
      disabled={disabled}
      padding={padding}
      borderRadius={borderRadius}
      title={title}
      fontWeight={fontWeight}
      aria-label={ariaLabel}
    >
      {children}
    </ButtonSecondStyled>
  );
};

export default ButtonSecond;
