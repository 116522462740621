import React from 'react';

import Img from './Img';
import { ItemAllergen, ItemAllergens } from './parts';

interface IProps {
  dietaryInformation: Nullable<
    {
      Name: string;
      IsEnabled: boolean;
      IconUrl: string;
    }[]
  >;
  'data-testid'?: string;
}
const ProductAllergens: React.FC<IProps> = ({
  dietaryInformation,
  'data-testid': dataTestId = 'product-allergens',
}) => {
  if (!dietaryInformation?.length) return null;

  const allergenList = dietaryInformation.reduce<React.JSX.Element[]>((acc, item) => {
    if (item.IsEnabled && item.IconUrl) {
      acc.push(
        <ItemAllergen className="ItemAllergen" key={item.Name}>
          <Img height="40px" width="40px" src={item.IconUrl} alt={item.Name} />
        </ItemAllergen>,
      );
    }
    return acc;
  }, []);

  if (!allergenList.length) return null;
  return (
    <ItemAllergens className="ItemAllergens" data-testid={dataTestId}>
      {allergenList}
    </ItemAllergens>
  );
};

export default ProductAllergens;
