import type {
  IBreakpoints,
  IDefaultTheme,
  IFontSizes,
  IFontWeight,
  IMedia,
} from './types/IDefault';

const breakpoints: IBreakpoints = {
  maxSm: 767,
  md: 768,
  lg: 1024,
  xl: 1170,
};

const customMediaQuery = (width: number, max = true): string => {
  return max ? `@media (max-width: ${width}px)` : `@media (min-width: ${width}px)`;
};

const media: IMedia = {
  custom: customMediaQuery,
  largeDesktop: customMediaQuery(breakpoints.xl, false),
  desktop: customMediaQuery(breakpoints.lg, false),
  tablet: customMediaQuery(breakpoints.md, false),
  phone: customMediaQuery(breakpoints.maxSm),
};

export const fontSizes: IFontSizes = {
  extraSmall: '12px',
  small: '14px',
  medium: '16px',
  large: '18px',
  extraLarge: '24px',
};

export const fontWeight: IFontWeight = {
  normal: 'normal',
  medium: 600,
  large: 'bold',
};

const Default: IDefaultTheme = {
  breakpoints,
  media,
  fontSizes,
  fontWeight,
  defaultDarkColor: 'var(--dark, #000000 )',
  defaultPrimaryColor: 'var(--primary-color, #3a7aaa)',
  defaultSecondaryColor: 'var(--secondary-color, #1b476a)',

  cardMobileWidth: 220,
  cardWidth: 360,
  cardWidthFromMdToLg: 318,
  gapBetweenCardsForLg: 20,
  gapBetweenCards: 30,
  percentForNCards: (
    n = 1,
    cardsInRow = 3,
    cardWidth = Default.cardWidth,
    gapBetweenCards = Default.gapBetweenCards,
  ) => {
    const x3CardsWidth = cardWidth * cardsInRow + gapBetweenCards * (cardsInRow - 1);

    if (n === 1) {
      return (cardWidth * 100) / (x3CardsWidth - gapBetweenCards);
    }

    const x2CardsWidth = cardWidth * n + gapBetweenCards;

    return (x2CardsWidth * 100) / x3CardsWidth;
  },
};

export default Default;
