import React, { FC, PropsWithChildren } from 'react';

import {
  ButtonCheckboxInputStyled,
  ButtonCheckboxLabelStyled,
  ButtonCheckboxSpanStyled,
} from '../parts';
import type { IButton } from '../types';

interface IProps
  extends Pick<IButton, 'width' | 'height' | 'margin' | 'padding' | 'fontWeight' | 'className'> {
  id: string;
  click: VoidFunction;
  isChecked: boolean;
}

const CLASSNAME_FOCUS = 'focus';

const ButtonCheckbox: FC<PropsWithChildren<IProps>> = ({
  id,
  isChecked,
  width,
  height,
  click,
  margin,
  padding,
  fontWeight,
  children,
  className = 'ButtonCheckbox',
}) => {
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') click();
  };
  const onFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
    const checkboxElement = event.target.parentElement;
    if (!checkboxElement?.classList.contains(CLASSNAME_FOCUS)) {
      checkboxElement?.classList.add(CLASSNAME_FOCUS);
    }
  };
  const onBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    const checkboxElement = event.target.parentElement;
    if (checkboxElement?.classList.contains(CLASSNAME_FOCUS)) {
      checkboxElement.classList.remove(CLASSNAME_FOCUS);
    }
  };
  return (
    <ButtonCheckboxLabelStyled
      htmlFor={id}
      isChecked={isChecked}
      className={className}
      width={width}
      height={height}
      margin={margin}
      padding={padding}
    >
      <ButtonCheckboxInputStyled
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={click}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <ButtonCheckboxSpanStyled isChecked={isChecked} fontWeight={fontWeight}>
        {children}
      </ButtonCheckboxSpanStyled>
    </ButtonCheckboxLabelStyled>
  );
};

export default ButtonCheckbox;
