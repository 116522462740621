import format from 'date-fns/format';
import React, { forwardRef, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { DatePicker, useDatePicker } from '@/shared/components/DatePicker';
import { FORMAT_DATE_TIME } from '@/shared/constants';
import { srSpeak } from '@/shared/utils/srSpeak';

import { Input, Wrapper } from '../parts';
import type { TModifier } from '../types';

interface IProps {
  modifier: TModifier;
  parenthandler: (modifier: TModifier, callback?: VoidFunction) => void;
  menuCalendar?: {
    AvailableDatesFoodOrder?: string[];
    NumberDaysAfter?: number;
  };
}

export const DateModifier: React.FC<IProps> = ({ modifier, parenthandler, menuCalendar }) => {
  const [selectedDate, setSelectedDate] = useState(modifier?.SelectedVariant || '');

  // TODO: подумать или уточнить о прокидывание данных до этого компонента
  const datePickerProps = useDatePicker({
    availableDates: menuCalendar?.AvailableDatesFoodOrder,
    numberDaysAfter: menuCalendar?.NumberDaysAfter,
  });

  const { formatMessage } = useIntl();

  const handler = (date: string, callback: VoidFunction): void => {
    const result = { ...modifier, SelectedVariant: date };
    parenthandler(result, callback);
  };

  const handlerCallback = (date: Date, callback: VoidFunction): void => {
    const formattedDate = format(date, FORMAT_DATE_TIME.MMMDDYYYY);
    setSelectedDate(formattedDate);
    handler(formattedDate, callback);
    datePickerProps.setOpenDatePicker(false);
    srSpeak(formatMessage({ id: 'GlobalComponents.Modifiers.Date.AriaLive.Changed' }));
  };

  const ForwardInput = useCallback(
    (props, ref) => {
      return <Input {...props} value={selectedDate} ref={ref} />;
    },
    [selectedDate],
  );

  const CustomInput = forwardRef(ForwardInput);

  if (!modifier) return null;
  return (
    <Wrapper>
      <DatePicker
        callback={handlerCallback}
        preSelectedMenuDate={selectedDate}
        customInput={<CustomInput />}
        isRemoveFocusOnEscape
        {...datePickerProps}
      />
    </Wrapper>
  );
};
