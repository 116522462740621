import { fetchMenu } from '@/shared/api/menu';

import { createSlice } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    loading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [fetchMenu.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [fetchMenu.pending.type]: (state) => {
      state.loading = true;
    },

    [fetchMenu.rejected.type]: (state) => {
      state.loading = false;
    },
  },
});

export const { setLoading } = loadingSlice.actions;

export const getLoading = ({ loading }: MenuRootState): boolean => loading.loading;

export default loadingSlice.reducer;
