import {
  addDays,
  compareAsc,
  eachDayOfInterval,
  format,
  isAfter,
  isSameDay,
  startOfDay,
} from 'date-fns';
import { useCallback, useMemo, useState } from 'react';

import { FORMAT_DATE_TIME } from '@/shared/constants';

type TDatePickerHookProps = {
  availableDates?: string[];
  numberDaysAfter?: number;
  onlyShowCurrentDaysOnlineOrdering?: boolean;
  onlyAllowFutureDaysOnlineOrdering?: boolean;
};

type TDatePickerHookResponse = {
  isOpenDatePicker: boolean;
  setOpenDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
  availableDates: string[];
  lastDateOnCalendar: string;
  disabled: boolean;
  minDate: string;
  filterDate: (date: Date) => boolean;
};
const DEFAULT_NUMBER_DAYS_AFTER = 30;

export const useDatePicker = ({
  availableDates,
  numberDaysAfter = DEFAULT_NUMBER_DAYS_AFTER,
  onlyShowCurrentDaysOnlineOrdering = false,
  onlyAllowFutureDaysOnlineOrdering = false,
}: TDatePickerHookProps): TDatePickerHookResponse => {
  const [isOpenDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const { YYYYMMDDTHHmmss } = FORMAT_DATE_TIME;
  const today = startOfDay(new Date());
  const lastDateOnCalendar = useMemo(
    () => format(addDays(today, numberDaysAfter || DEFAULT_NUMBER_DAYS_AFTER), YYYYMMDDTHHmmss),
    [YYYYMMDDTHHmmss, numberDaysAfter, today],
  );

  const newAvailableDates = useMemo(() => {
    /**
     * ModalProduct has no calendar data in the cart,
     * so for it we create a list of dates from today plus 30 days (or numberDaysAfter)
     * */
    if (!availableDates?.length)
      return eachDayOfInterval({
        start: today,
        end: new Date(lastDateOnCalendar),
      }).map((date) => format(date, YYYYMMDDTHHmmss));

    const sortDates = [...availableDates].sort((a, b) => compareAsc(new Date(a), new Date(b)));
    if (!onlyAllowFutureDaysOnlineOrdering) return sortDates;

    return sortDates.filter((date) => isAfter(new Date(date), today));
  }, [
    YYYYMMDDTHHmmss,
    availableDates,
    lastDateOnCalendar,
    onlyAllowFutureDaysOnlineOrdering,
    today,
  ]);

  const minDate = useMemo(() => {
    if (newAvailableDates.length > 0) return newAvailableDates[0];
    return format(today, YYYYMMDDTHHmmss);
  }, [YYYYMMDDTHHmmss, newAvailableDates, today]);

  const filterDate = useCallback(
    (renderDate: Date) => {
      return newAvailableDates?.some((date) => isSameDay(renderDate, new Date(date)));
    },
    [newAvailableDates],
  );

  return {
    isOpenDatePicker,
    setOpenDatePicker,
    availableDates: newAvailableDates,
    lastDateOnCalendar,
    disabled: onlyShowCurrentDaysOnlineOrdering,
    minDate,
    filterDate,
  };
};
