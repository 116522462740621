import styled, { css } from 'styled-components';

import type { IDefaultTheme } from '@/shared/themes/types';

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '767px',
};

export const DatePickerButton = styled.button`
  width: 100%;
  border: none;
  height: 40px;
  margin: 1px 1px 1px 1px;
  padding: 1px 2px 2px 16px;
  border-radius: 22.5px;
  background-color: #efefef;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  position: relative;

  :disabled {
    cursor: not-allowed;
  }

  @media (max-width: ${size.tablet}) {
    padding: 4px 2px 2px 21px;
    margin-bottom: 16px;
    margin-right: 1em;
  }
`;

export const DateTextSpan = styled.span`
  margin: 6px 16px 6px 0;

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  width: max-content;

  @media (max-width: ${size.tablet}) {
    display: unset;
  }
`;

export const DateMealSelectorFilterButton = styled.div`
  margin-left: auto;
  height: 37px;
  width: 56px;
  padding: 2px 16px;
  border-radius: 0px 22.5px 22.5px 0px;
  background-color: #ffffff;
  border: none;
  outline: none;

  @media (max-width: ${size.tablet}) {
    right: 1px;
    position: absolute;
    top: 1px;
  }
`;

export const Svg = styled.svg`
  height: 23px;
  position: relative;
  top: 6px;
  width: 2em;
  display: inline-block;
  fill: ${({ theme }: { theme: IDefaultTheme }) => theme.defaultPrimaryColor};
`;

export const LineThrough = styled.span`
  ${({ tooltipText }: { tooltipText: string }) => css`
    cursor: ${tooltipText === 'No Menu' ? 'not-allowed' : ''};
    text-decoration: ${tooltipText === 'No Menu' ? 'line-through' : ''};

    color: ${tooltipText === 'No Menu' || tooltipText === 'Menu data unavailable' ? '#ccc' : ''};
    &:focus {
      border: 1px solid;
    }
  `}
`;
