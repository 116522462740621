import styled, { css } from 'styled-components';

export const CalcMsgStyled = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.normal};
    font-weight: ${theme.fontWeight.medium};
  `};
`;

export const ProductDescriptionDiv = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: auto;
    padding: 16px 32px 0 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;

    ${theme.media.phone} {
      flex-direction: column;
    }
  `};
`;

export const Input = styled.input`
  ${() => css`
    width: 100%;
    margin: 8px 0 0;
    padding: 8px;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px #000;
    background-color: #fff;
  `}
`;

export const InputHidden = styled.input`
  ${() => css`
    height: 0;
    width: 0;
    position: absolute;
    left: -9000px;
  `}
`;
export const ModalBlock = styled.div`
  margin: 0 0 16px 0;
`;
export const ModalSubTitle = styled.legend`
  font-size: 14px;
  color: #000;
`;
export const Wrapper = styled.div`
  ${() => css`
    display: block;
  `}
`;

export const ItemInfo = styled.div<{ isDescription?: boolean; IsDeemphasized?: boolean }>`
  ${({ isDescription, IsDeemphasized }) => css`
    min-height: ${isDescription ? '40px' : ''};
    font-size: 16px;
    color: #000000;
    width: 100%;
    padding: ${IsDeemphasized ? '16px 8px 8px 8px' : '16px 8px 0 8px'};
    display: flex;
    justify-content: start;
  `}
`;
export const ModalTitle = styled.div`
  font-size: 18px;
  color: #000;
`;

export const ModalBody = styled.div`
  padding-bottom: 32px;
`;
export const OrderDate = styled.span`
  display: inline-block;
  height: 24px;
  margin: 0 0 0 8px;
  padding: 0 8px;
  border-radius: 4px;
  background-color: #efefef;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  color: #000;
`;

export const ModalProductTextArea = styled.textarea`
  width: 100%;
  height: 102px;
  margin: 8px 0 0;
  padding: 8px;
  box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #000;
  background-color: #fff;
`;

export const ModalProductDescription = styled.div`
  margin-bottom: 1em;
`;

export const ModalProductDescriptionContent = styled.h3`
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  font-weight: normal;
  color: #000;
`;
export const ModalProductDescriptionItemDetails = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
`;
export const ModalProductServingSize = styled.div`
  margin: 1rem auto;
  font-size: 16px;
`;
export const ModalProductDisclaimer = styled.p`
  ${({
    margin = '0 0 16px 0',
    fontWeight = 'normal',
  }: {
    margin?: string;
    fontWeight?: string;
  }) => css`
    color: #000;
    font-size: 12px;
    margin: ${margin};
    font-weight: ${fontWeight};
    text-transform: uppercase;
  `}
`;
export const ModalHeaderItem = styled.div`
  width: 100%;
  padding: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 1px 4px 0 rgb(51 51 51 / 10%);
  background-color: #ffffff;
  display: inline-flex;
  height: 88px;
`;
export const ModalHeaderItemName = styled.h2`
  justify-content: start;
  align-items: center;
  display: -webkit-box;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  letter-spacing: normal;
  color: #000000;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: 18px;
  padding: 0;
  margin: auto 40px auto 32px !important;
  -webkit-line-clamp: 3;
  width: 100%;
  line-height: 22px;
`;

export const WrapRequired = styled.div`
  position: relative;
  margin: 0 0 8px 0;
  font-size: 14px;
`;
export const WarningDiv = styled.div`
  display: ${({ isShow }: { isShow: boolean }) => (isShow ? 'flex' : 'none')};
  gap: 10px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.defaultPrimaryColor};
  width: fit-content;
  padding: 4px 10px;
  margin-bottom: 8px;

  & > svg {
    position: relative;
    top: 1px;
  }
`;

export const WarningContainer = styled.div`
  font-size: 14px;
  color: white;
`;
export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;
export const ModalProductFooter = styled.div`
  color: #000;
  padding: 24px 32px;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: end;
  flex-direction: row;
  justify-content: flex-end;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  border-radius: 4px;
  background-color: #efefef;
  gap: 16px;

  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;
