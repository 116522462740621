import { PREFIX_URL_API } from '@/shared/constants';
import { apiClient } from '@/shared/utils/apiClient';

import type {
  IApiResponseCartFoodProductDetails,
  IApiResponseGetUpdateFoodOrder,
  IApiResponseProceedToCheckout,
  IApiResponseRemoveItemByGroupId,
  IApiResponseUpdateFoodProductToCart,
  IApiResponseUpdateLocationAndTimeForOrdering,
  TApiCartFoodProductDetails,
  TApiGetUpdateFoodOrder,
  TApiProceedToCheckout,
  TApiRemoveItemByGroupId,
  TApiUpdateFoodProductToCart,
  TApiUpdateLocationAndTimeForOrdering,
} from './types';

export const getUpdateFoodOrder: TApiGetUpdateFoodOrder = ({
  locationId,
  basketName,
  pickupTimeIncrement,
  date,
  periodId,
  storeIds,
}) => {
  const url = `${
    PREFIX_URL_API.CART
  }/GetUpdateFoodOrder?locationId=${locationId}&basket=${basketName}&pickupTimeIncrement=${pickupTimeIncrement}&date=${date}&periodId=${periodId}&storeId=${
    storeIds || ''
  }`;
  return apiClient.get<IApiResponseGetUpdateFoodOrder>(url);
};

export const removeItemByGroupId: TApiRemoveItemByGroupId = (payload) => {
  const url = `${PREFIX_URL_API.CART}/DeleteBasketLineItem`;
  return apiClient.post<IApiResponseRemoveItemByGroupId>(url, payload);
};

export const cartFoodProductDetails: TApiCartFoodProductDetails = ({ basket, item, prod }) => {
  const url = `${PREFIX_URL_API.CART}/GetFoodProduct?basket=${basket}&item=${item}&prod=${prod}`;
  return apiClient.get<IApiResponseCartFoodProductDetails>(url);
};

export const proceedToCheckout: TApiProceedToCheckout = (basket) => {
  const url = `${PREFIX_URL_API.CART}/ProceedToCheckout`;
  return apiClient.post<IApiResponseProceedToCheckout>(url, { basket });
};

export const updateFoodProductToCart: TApiUpdateFoodProductToCart = (payload) => {
  const url = `${PREFIX_URL_API.CART}/UpdateFoodProduct`;
  return apiClient.post<IApiResponseUpdateFoodProductToCart>(url, payload);
};

export const updateLocationAndTimeForOrdering: TApiUpdateLocationAndTimeForOrdering = (payload) => {
  const url = `${PREFIX_URL_API.CART}/UpdateFoodOrder`;
  return apiClient.post<IApiResponseUpdateLocationAndTimeForOrdering>(url, payload);
};
