import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ErrorMessage } from '@/shared/components/ErrorMessage';
import { RequiredField } from '@/shared/components/Required';
import { MODIFIER_TYPES } from '@/shared/constants';

import { ModalBlock, ModalSubTitle } from '../parts';
import type { TModifier, TProductDataForCart } from '../types';
import { DateModifier } from './DateModifier';
import { SelectManyModifier } from './SelectManyModifier';
import { SelectOneModifier } from './SelectOneModifier';
import { TextModifier } from './TextModifier';
import { YesNoModifier } from './YesNoModifier';

interface IProps {
  modifiers: TModifier[];
  setData: React.Dispatch<React.SetStateAction<TProductDataForCart>>;
  menuCalendar?: {
    AvailableDatesFoodOrder?: string[];
    NumberDaysAfter?: number;
  };
}
export const Modifiers: React.FC<IProps> = ({ modifiers, setData, menuCalendar }) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { formatMessage } = useIntl();

  const handler = (modifier: TModifier, callback?: VoidFunction): void => {
    const newModifiers = modifiers.map((item) => {
      return item.ModifierId === modifier.ModifierId ? modifier : item;
    });

    setData((item) => {
      return {
        ...item,
        Product: {
          ...item.Product,
          Modifiers: newModifiers,
        },
      };
    });
    callback?.();
  };

  const modifierMap = {
    [MODIFIER_TYPES.SELECT_MANY.const]: SelectManyModifier,
    [MODIFIER_TYPES.SELECT_ONE.const]: SelectOneModifier,
    [MODIFIER_TYPES.TEXT.const]: TextModifier,
    [MODIFIER_TYPES.DATE.const]: DateModifier,
    [MODIFIER_TYPES.YES_NO.const]: YesNoModifier,
  };

  return (
    <>
      {modifiers.map((item) => {
        const labelSelectOne = item.DisplayName;
        const labelText = `${item.DisplayName} ($${item.ModifierPrice})`;
        const label =
          item.ModifierType === MODIFIER_TYPES.SELECT_ONE.const ? labelSelectOne : labelText;
        const messages = {
          required: formatMessage({ id: 'Required' }, { 0: label }),
        };

        const ModifierComponent = modifierMap[item.ModifierType] || YesNoModifier;

        const isRequired =
          item.ModifierType === MODIFIER_TYPES.SELECT_ONE.const ||
          (item.ModifierType === MODIFIER_TYPES.TEXT.const && item.IsTextRequired);

        const isError = !!errors[item?.ModifierId];
        return (
          <ModalBlock key={item.ModifierId} className="ModalBlock" aria-required={isRequired}>
            <fieldset
              {...(isError && {
                'aria-describedby': `error-${item?.DisplayName}`,
              })}
            >
              <ModalSubTitle className="ModalSubTitle">
                {item.DisplayName.toUpperCase()}
                {isRequired && <RequiredField />}
              </ModalSubTitle>
              <ModifierComponent
                modifier={item}
                parenthandler={handler}
                menuCalendar={menuCalendar}
              />
            </fieldset>

            {isError && errors[item.ModifierId] && (
              <ErrorMessage id={`error-${item?.ModifierId}`}>
                <span dangerouslySetInnerHTML={{ __html: messages.required }} />
              </ErrorMessage>
            )}
          </ModalBlock>
        );
      })}
    </>
  );
};
