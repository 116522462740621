import type { TDetails, TPortalModalComponent, TPropsFromOpenMode } from './type';

const defaultEventName = 'open';

const ModalService = {
  on(callback: (details: TDetails) => void, eventName = defaultEventName): VoidFunction {
    // any as a hack for addEventListener
    const handlerCustomEvent = (e: any): void => {
      callback(e.detail);
    };

    document.addEventListener(eventName, handlerCustomEvent);

    return () => document.removeEventListener(eventName, handlerCustomEvent);
  },
  open<P>(
    component: TPortalModalComponent<P>,
    props?: TPropsFromOpenMode<P>,
    eventName = defaultEventName,
  ) {
    const event = new CustomEvent<TDetails<P>>(eventName, { detail: { component, props } });
    document.dispatchEvent(event);
  },
};

export default ModalService;
