import styled from 'styled-components';

export const ErrorText = styled.div<{ isInfo?: boolean }>`
  color: ${({ theme, isInfo }) => (isInfo ? theme.defaultPrimaryColor : '#a94442')};
  font-size: 14px;
  height: max-content;
`;

export const Svg = styled.svg<{ isInfo?: boolean }>`
  height: 1.25em;
  position: relative;
  top: 3px;
  width: 1.25em;
  fill: ${({ theme, isInfo }) => (isInfo ? theme.defaultPrimaryColor : '#FF0000')};
`;
