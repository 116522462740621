import braintree, { BraintreeError, callback } from 'braintree-web';
import { useEffect, useRef, useState } from 'react';

import { DEFAULT_CURRENCY } from '@/shared/constants';

import { loadScript } from '@paypal/paypal-js';

import { BRAINTREE_STATUS } from './constants';
import type { TBraintree, TBraintreeHook, TPayPalHook } from './types';

let payPalSDKPromise: Promise<TBraintree> | null;

export const useBraintree: TBraintreeHook = ({
  clientToken,
  amount,
  components = 'buttons,messages',
  callbackForError,
}) => {
  const [status, setStatus] = useState<BRAINTREE_STATUS>(
    amount || components === 'messages' ? BRAINTREE_STATUS.INIT : BRAINTREE_STATUS.ERROR,
  );

  const restart = (): void => {
    if (!(status === BRAINTREE_STATUS.ERROR && (amount || components === 'messages'))) {
      console.log("Can't restart");
      return;
    }

    payPalSDKPromise = null;
    setStatus(BRAINTREE_STATUS.INIT);
  };
  const defaultValue = {
    braintreeClient: undefined,
    payPalCheckoutInstance: undefined,
    deviceData: undefined,
    clientToken: undefined,
  };
  const braintreeRef = useRef<TBraintree>(defaultValue);

  useEffect(() => {
    if (!clientToken) return;

    if (!amount && components !== 'messages') {
      console.warn('No amount to pay via PayPal');
    }

    if (status === BRAINTREE_STATUS.INIT && !payPalSDKPromise) {
      const handlerError = (error: BraintreeError): void => {
        callbackForError();
        setStatus(BRAINTREE_STATUS.ERROR);
        console.error(error);
      };
      setStatus(BRAINTREE_STATUS.LOADING);
      payPalSDKPromise = new Promise((resolve) => {
        const callbackLoadPayPalSDK: callback<braintree.PayPalCheckout> = (
          errLoadPayPalSDK,
          payPalCheckoutInstance,
        ) => {
          if (errLoadPayPalSDK) {
            handlerError(errLoadPayPalSDK);
          }
          braintreeRef.current.payPalCheckoutInstance = payPalCheckoutInstance;
          braintreeRef.current.clientToken = clientToken;
          // WARNING: required when reselecting paypal
          resolve(braintreeRef.current);
        };
        const callbackPaypalCheckout: callback<braintree.PayPalCheckout> = (
          errPaypalCheckout,
          payPalCheckoutInstance,
        ) => {
          if (errPaypalCheckout) {
            handlerError(errPaypalCheckout);
            return;
          }

          payPalCheckoutInstance!.loadPayPalSDK(
            {
              components,
              currency: DEFAULT_CURRENCY,
              intent: 'capture',
              debug: false, // for testing
              ...(amount && {
                // 'enable-funding': 'paylater',
                // 'buyer-country': 'US', // for test? delete after
                dataAttributes: {
                  // @ts-expect-error: property exists, typing error
                  amount: amount.toFixed(2),
                },
              }),
            },
            callbackLoadPayPalSDK,
          );
        };
        const callbackDeviceData: callback<braintree.DataCollector> = (
          errDataCollector,
          dataCollector,
        ) => {
          if (errDataCollector) {
            handlerError(errDataCollector);
            return;
          }
          braintreeRef.current.deviceData = dataCollector?.deviceData;
        };
        const callbackClient: callback<braintree.Client> = (
          errBraintreeClient,
          braintreeClient,
        ) => {
          if (errBraintreeClient) {
            handlerError(errBraintreeClient);
            return;
          }

          braintreeRef.current.braintreeClient = braintreeClient;
          braintree.dataCollector.create({ client: braintreeClient! }, callbackDeviceData);
          braintree.paypalCheckout.create({ client: braintreeClient }, callbackPaypalCheckout);
        };
        braintree.client.create({ authorization: clientToken }, callbackClient);
      });
    }
  }, [amount, callbackForError, clientToken, components, status]);

  useEffect(() => {
    if (payPalSDKPromise && status === BRAINTREE_STATUS.LOADING) {
      payPalSDKPromise.then((data) => {
        // WARNING: required when reselecting paypal
        braintreeRef.current = data;

        setStatus(BRAINTREE_STATUS.COMPLETE);
        console.info('PayPal successfully uploaded and launched!');
      });
    }
  }, [status]);

  return { ...braintreeRef.current, status, restart };
};

export const usePayPal: TPayPalHook = ({ clientId, isLoadScript }) => {
  useEffect(() => {
    if (window?.paypal) return;

    if (clientId && isLoadScript) {
      // Run once if condition is true
      loadScript({ clientId, components: 'messages' });
    }
  }, [clientId, isLoadScript]);
};
