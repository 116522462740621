import React, { useMemo } from 'react';

import { useCurrencyFormmater } from '@/shared/hooks/currencyFormmater';

export interface IModifierProps {
  modifiers: {
    Id: string;
    Price: number;
    Name: string;
    Data: string | '';
  }[];
}

const Modifiers: React.FC<IModifierProps> = ({ modifiers }) => {
  const format = useCurrencyFormmater();

  const blockText: string = useMemo(() => {
    const groups = new Map<string, IModifierProps['modifiers']>();
    modifiers.forEach((modifier) => {
      let group = '';
      let name = '';

      if (!!modifier.Data && modifier.Data !== '') {
        group = modifier.Name;
        name = modifier.Data;
      } else {
        [group, name] = modifier.Name.split(' (').map((substr) => substr.split(')')[0]);
        if (!name) {
          name = group;
          group = 'Misc';
        }
      }
      const newItem = {
        ...modifier,
        Name: name,
      };
      if (groups.has(group)) {
        groups.set(group, [...(groups.get(group) as IModifierProps['modifiers']), newItem]);
      } else {
        groups.set(group, [newItem]);
      }
    });

    const groupsArray = Array.from(groups);
    return groupsArray
      .map(([group, mods]) => {
        return `${group}: ${mods
          .map((m) => m.Name + (m.Price ? ` (${format(m.Price)})` : ''))
          .join(', ')}`;
      })
      .join('. ');
  }, [modifiers, format]);

  return <div>{blockText}</div>;
};
export default Modifiers;
