import React, { FC, PropsWithChildren } from 'react';

import { ButtonTextStyled } from '../parts';
import type { IButton } from '../types';

interface IProps
  extends Pick<
    IButton,
    | 'width'
    | 'height'
    | 'margin'
    | 'padding'
    | 'bg'
    | 'fontWeight'
    | 'aria-label'
    | 'fontSize'
    | 'isDark'
    | 'className'
  > {
  click?: VoidFunction;
  title?: string;
  disabled?: boolean;
  'data-testid'?: string;
}

const ButtonText: FC<PropsWithChildren<IProps>> = ({
  click,
  width,
  height,
  margin,
  padding,
  bg,
  title,
  isDark,
  disabled,
  children,
  className = 'ButtonText',
  'data-testid': dataTestId = 'button-text',
}) => {
  return (
    <ButtonTextStyled
      className={className}
      onClick={click}
      width={width}
      height={height}
      margin={margin}
      isDark={isDark}
      disabled={disabled}
      padding={padding}
      bg={bg}
      title={title}
      data-testid={dataTestId}
    >
      {children}
    </ButtonTextStyled>
  );
};

export default ButtonText;
