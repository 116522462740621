import React, { FC, PropsWithChildren } from 'react';

import { ButtonLinkStyled } from '../parts';
import type { IButton } from '../types';

interface IProps
  extends Pick<
    IButton,
    | 'width'
    | 'height'
    | 'margin'
    | 'padding'
    | 'fontWeight'
    | 'aria-label'
    | 'fontSize'
    | 'isDark'
    | 'className'
  > {
  link: string;
  disabled?: boolean;
}
const ButtonLink: FC<PropsWithChildren<IProps>> = ({
  link,
  width,
  height,
  margin,
  padding,
  fontWeight,
  fontSize,
  isDark,
  'aria-label': ariaLabel,
  disabled,
  children,
  className = 'ButtonLink',
}) => {
  const handleLinkClick = (): void => {
    window.location.assign(link);
  };
  return (
    <ButtonLinkStyled
      className={className}
      onClick={handleLinkClick}
      width={width}
      height={height}
      margin={margin}
      isDark={isDark}
      disabled={disabled}
      padding={padding}
      fontSize={fontSize}
      fontWeight={fontWeight}
      aria-label={ariaLabel}
    >
      {children}
    </ButtonLinkStyled>
  );
};

export default ButtonLink;
