import React, { CSSProperties, FC } from 'react';
import { ClipLoader } from 'react-spinners';

import { HiddenLoadingElement } from '@/shared/services/modal';

const cssOverride: CSSProperties = {
  display: 'block',
  margin: 'auto',
};
const Loader: FC = () => {
  return (
    <div>
      <ClipLoader color="#000000" cssOverride={cssOverride} size={50} />
      <HiddenLoadingElement />
    </div>
  );
};

export default Loader;
