import { isAfter, isBefore, isSameDay } from 'date-fns';
import React, { PropsWithChildren, ReactNode, memo, useCallback, useRef } from 'react';
import DPiker, { CalendarContainer } from 'react-datepicker';
import { useIntl } from 'react-intl';

import { LineThrough } from './parts';

const isBeforeOrAfterDay = (date: Date, minDate: Date, lastDate: Date): boolean => {
  return isBefore(date, minDate) || isAfter(date, lastDate);
};

interface IProps {
  callback: (date: Date, focus: () => void) => void;
  preSelectedMenuDate: string | null | undefined;
  customInput?: React.ReactNode;
  isOpenDatePicker: boolean;
  setOpenDatePicker: (value: boolean) => void;
  availableDates: string[];
  lastDateOnCalendar: string;
  disabled: boolean;
  minDate: string;
  filterDate: (date: Date) => boolean;
  isRemoveFocusOnEscape?: boolean;
}
const DatePicker: React.FC<IProps> = ({
  callback,
  preSelectedMenuDate,
  customInput,
  isOpenDatePicker,
  setOpenDatePicker,
  availableDates,
  lastDateOnCalendar,
  disabled,
  minDate,
  filterDate,
  isRemoveFocusOnEscape,
}) => {
  const { formatMessage } = useIntl();

  const messages = {
    menuAvailable: formatMessage({ id: 'MenuAvailable' }),
    noMenuTooltip: formatMessage({ id: 'NoMenuTooltip' }),
    menuUnavailable: formatMessage({ id: 'MenuUnavailable' }),
    datePickerDescription: formatMessage({ id: 'DatePickerDescription' }),
  };

  const selectedDate = preSelectedMenuDate ? new Date(preSelectedMenuDate) : null;

  type TRenderDate = {
    renderDay: number;
    renderDate: Date | undefined;
  };

  const handlerTooltipText = useCallback(
    (renderDate: TRenderDate['renderDate']): string => {
      if (!renderDate) {
        return messages.menuUnavailable;
      }
      const isAvailableDate = availableDates?.some((date) => {
        return isSameDay(renderDate, new Date(date));
      });

      if (isAvailableDate) return messages.menuAvailable;

      const isBeforeMinDateOrAfterLastDate = isBeforeOrAfterDay(
        renderDate,
        new Date(minDate),
        new Date(lastDateOnCalendar),
      );

      if (isBeforeMinDateOrAfterLastDate) return messages.menuUnavailable;

      return messages.noMenuTooltip;
    },
    [
      availableDates,
      lastDateOnCalendar,
      messages.menuAvailable,
      messages.menuUnavailable,
      messages.noMenuTooltip,
      minDate,
    ],
  );

  const renderDayContents = useCallback(
    (renderDay: TRenderDate['renderDay'], renderDate: TRenderDate['renderDate']): ReactNode => {
      const tooltipText = handlerTooltipText(renderDate);

      return (
        <LineThrough title={tooltipText} tooltipText={tooltipText} aria-label={tooltipText}>
          {renderDay}
        </LineThrough>
      );
    },
    [handlerTooltipText],
  );

  const datePickerRef = useRef<any>(null);
  const focusInput = (): void => {
    // Return focus to input (button) after closing datepicker
    setTimeout(() => (datePickerRef.current?.input as HTMLButtonElement)?.focus(), 100);
  };

  const handlerOnChange = useCallback(
    (date: Date | null): void => {
      if (date) callback(date, focusInput);
    },
    [callback],
  );

  const handlerOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Escape' && isOpenDatePicker) {
      event.stopPropagation();
      setOpenDatePicker(false);

      // You need to use setTimeout, without it the focus disappears
      if (!isRemoveFocusOnEscape) setTimeout(focusInput);
    }
  };

  const clickOutsideOpenPiker = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (isOpenDatePicker) {
      event.stopPropagation();
      setOpenDatePicker(false);
    }
  };

  const handlerOnFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (isRemoveFocusOnEscape) {
      event.stopPropagation();
      setOpenDatePicker(!isOpenDatePicker);
    }
  };
  const CustomCalendarContainer = useCallback(
    (props: PropsWithChildren<{ className: string | undefined }>) => {
      return (
        <div id="react-datepicker" role="application" aria-label={messages.datePickerDescription}>
          <CalendarContainer className={props.className}>
            <div style={{ position: 'relative' }}>{props.children}</div>
          </CalendarContainer>
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <DPiker
      open={isOpenDatePicker}
      minDate={new Date(minDate)}
      selected={selectedDate}
      onFocus={handlerOnFocus}
      onKeyDown={handlerOnKeyDown}
      onChange={handlerOnChange}
      renderDayContents={renderDayContents}
      filterDate={filterDate}
      calendarContainer={CustomCalendarContainer}
      customInput={customInput}
      ref={datePickerRef}
      disabled={disabled}
      onClickOutside={clickOutsideOpenPiker}
      renderCustomHeader={({
        monthDate,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        decreaseMonth,
        increaseMonth,
      }) => (
        <div>
          {!prevMonthButtonDisabled && (
            <button
              type="button"
              aria-label="Previous Month"
              className="react-datepicker__navigation react-datepicker__navigation--previous"
              onClick={decreaseMonth}
            >
              <span
                aria-hidden="true"
                className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
              >
                {'<'}
              </span>
            </button>
          )}
          <h3 className="react-datepicker__current-month">
            {monthDate.toLocaleString('en-US', {
              month: 'long',
              year: 'numeric',
            })}
          </h3>
          {!nextMonthButtonDisabled && (
            <button
              type="button"
              aria-label="Next Month"
              className="react-datepicker__navigation react-datepicker__navigation--next"
              onClick={increaseMonth}
            >
              <span
                aria-hidden="true"
                className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
              >
                {'>'}
              </span>
            </button>
          )}
        </div>
      )}
    />
  );
};

export default memo(DatePicker);
