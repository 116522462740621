import { useRef } from 'react';

export const useFocus = <T>(): [React.RefObject<T>, VoidFunction] => {
  const htmlElRef = useRef<T>(null);
  const setFocus = (): void => {
    (htmlElRef?.current as HTMLElement)?.focus();
  };

  return [htmlElRef, setFocus];
};
