import React from 'react';

import {
  DescriptionProductOfComposition,
  InternalList,
  Underline,
  UnderlineInnerList,
} from './parts';
import { NutritionalTree } from './types';

interface NutritionalItemProps {
  item: NutritionalTree[number];
  sumByNesting?: number;
}

export const NutritionalItem: React.FC<NutritionalItemProps> = ({ item, sumByNesting = 1 }) => {
  const isFirstNesting = sumByNesting === 1;
  const isSubList = !!(item.SubList && item.SubList.length > 0);

  return (
    <DescriptionProductOfComposition
      {...(!isFirstNesting && { fontWeight: 'normal' })}
      data-nesting={sumByNesting}
    >
      {item.Name}
      <span>
        {item.Value} {item.Unit}
      </span>

      {isSubList && (
        <InternalList>
          {item.SubList!.map((subItem) => (
            <React.Fragment key={subItem.Name}>
              <NutritionalItem item={subItem} sumByNesting={sumByNesting + 1} />
            </React.Fragment>
          ))}
        </InternalList>
      )}
      {!isSubList &&
        (isFirstNesting ? (
          <Underline />
        ) : (
          <UnderlineInnerList
            {...(sumByNesting > 1 && {
              num: sumByNesting - 1,
            })}
          />
        ))}
    </DescriptionProductOfComposition>
  );
};
