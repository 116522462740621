import { PREFIX_URL_API } from '@/shared/constants';
import { apiClient } from '@/shared/utils/apiClient';

import type {
  IApiResponseAddOrRemoveFromLocationFavorite,
  TApiAddOrRemoveFromLocationFavorite,
} from './types';

export const addOrRemoveFromLocationFavoriteApi: TApiAddOrRemoveFromLocationFavorite = (
  postData,
) => {
  const url = `${PREFIX_URL_API.LOCATIONS}/AddOrRemoveFromFavorite`;
  return apiClient.post<IApiResponseAddOrRemoveFromLocationFavorite>(url, postData);
};
