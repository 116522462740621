import styled, { css } from 'styled-components';

import type { IDefaultTheme } from '@/shared/themes/types';

import type { IButton } from './types';

const lightColor = '#fff';
const darkColor = '#000000';

// TODO: разделить все стили, переиспользовать переменную.

export const ButtonComponent = styled.button`
  ${({
    theme,
    width = '84px',
    height = '36px',
    padding = '0',
    margin = '0',
    fontSize = '16px',
    fontWeight = 'bold',
    isDark,
    borderRadius = '2px',
  }: Pick<
    IButton,
    | 'theme'
    | 'isDark'
    | 'width'
    | 'height'
    | 'margin'
    | 'padding'
    | 'fontSize'
    | 'fontWeight'
    | 'borderRadius'
  >) => css`
    width: ${width};
    height: ${height};
    padding: ${padding};
    border-radius: ${borderRadius};
    background-color: ${isDark ? theme.defaultPrimaryColor : lightColor};
    margin: ${margin};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: ${isDark ? lightColor : theme.defaultPrimaryColor};
    border: ${isDark ? '0 solid' : `1px solid ${theme.defaultPrimaryColor}`};
    cursor: pointer;

    :disabled {
      background-color: grey;
    }
    &:focus {
      outline: #000 solid 2px;
      outline-offset: -4px;
    }

    :hover {
      color: white;
      background-color: ${theme.defaultSecondaryColor};
    }
  `}
`;

export const ButtonSecondStyled = styled(ButtonComponent)`
  ${({
    theme,
    width = 'max-content',
    height = '40px',
    padding = '8px 24px',
    margin = '0',
    fontWeight = 'normal',
    borderRadius = '4px',
    isDark,
  }: {
    theme: IDefaultTheme;
    padding?: string;
    margin?: string;
    width?: string;
    height?: string;
    borderRadius?: string;
    fontWeight?: string;
    isDark?: boolean;
  }) => css`
    width: ${width};
    height: ${height};
    padding: ${padding};
    font-weight: ${fontWeight};
    border-radius: ${borderRadius};
    background-color: ${theme.defaultPrimaryColor};
    margin: ${margin};
    color: white;

    :hover {
      border: 1px solid ${!isDark ? lightColor : theme.defaultPrimaryColor};
    }

    :disabled {
      color: ${lightColor};
      border: none;
      background-color: grey;
    }
    :focus {
      outline: ${isDark ? lightColor : theme.defaultPrimaryColor} solid 2px;
      outline-offset: -4px;
    }
  `}
`;

export const ButtonTextStyled = styled(ButtonComponent)`
  ${({
    theme,
    width = 'max-content',
    height = '40px',
    padding = '0',
    margin = '0',
    bg,
    isDark,
  }: Pick<IButton, 'theme' | 'isDark' | 'width' | 'height' | 'margin' | 'padding' | 'bg'>) => css`
    width: ${width};
    height: ${height};
    padding: ${padding};
    background-color: ${bg || (!isDark ? lightColor : theme.defaultPrimaryColor)};
    margin: ${margin};
    color: ${!isDark ? theme.defaultPrimaryColor : lightColor};
    border: ${isDark ? '1px solid' : '0px solid'};
    text-align: start;
    line-height: 1.3;

    &:focus {
      outline: ${darkColor} solid 2px;
      outline-offset: -2px;
    }

    :hover {
      color: ${!isDark ? theme.defaultPrimaryColor : lightColor};
      background-color: ${bg || (!isDark ? lightColor : theme.defaultPrimaryColor)};
    }
  `}
`;

export const ButtonLinkStyled = styled(ButtonComponent)`
  ${({
    theme,
    width = 'max-content',
    height = '40px',
    padding = '0',
    margin = '0px 16px 0px 0px',
    fontSize = '16px',
    fontWeight = 'normal',
    isDark,
  }: Pick<
    IButton,
    'theme' | 'isDark' | 'width' | 'height' | 'margin' | 'padding' | 'fontSize' | 'fontWeight'
  >) => css`
    width: ${width};
    height: ${height};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    padding: ${padding};
    background-color: ${!isDark ? lightColor : theme.defaultPrimaryColor};
    margin: ${margin};
    color: ${!isDark ? theme.defaultPrimaryColor : lightColor};
    border: ${isDark ? '0px solid' : `1px solid ${theme.defaultPrimaryColor}`};

    :hover {
      color: ${!isDark ? theme.defaultPrimaryColor : lightColor};
      background-color: ${!isDark ? lightColor : theme.defaultPrimaryColor};
    }
    &:focus {
      outline: ${isDark ? lightColor : theme.defaultPrimaryColor} solid 2px;
      outline-offset: -4px;
    }
  `}
`;

export const ButtonCheckboxLabelStyled = styled.label`
  ${({
    theme,
    isChecked,
    padding = '0',
    margin = '0',
    width = '84px',
    height = '36px',
  }: Pick<IButton, 'theme' | 'width' | 'height' | 'margin' | 'padding'> & {
    isChecked: boolean;
  }) => css`
    display: block;
    padding: ${padding};
    border-radius: 2px;
    margin: ${margin};
    user-select: none;
    position: relative;
    background-color: ${isChecked ? theme.defaultPrimaryColor : lightColor};
    border: ${!isChecked && `1px solid ${theme.defaultPrimaryColor}`};
    cursor: pointer;
    width: ${width};
    height: ${height};
    text-align: center;

    &.focus {
      outline: #000 solid 2px;
    }
  `}
`;

export const ButtonCheckboxSpanStyled = styled.span`
  ${({
    theme,
    fontSize = '16px',
    fontWeight = 'bold',
    isChecked,
  }: Pick<IButton, 'theme' | 'fontSize' | 'fontWeight'> & {
    isChecked: boolean;
  }) => css`
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${isChecked ? lightColor : theme.defaultPrimaryColor};
  `}
`;

export const ButtonCheckboxInputStyled = styled.input`
  z-index: -1;
  opacity: 0;
  display: block;
  height: 0.1px;
`;

export const ButtonAddToFav = styled.button`
  padding: 0;
  height: 24px;
  float: right;
  margin: 4px 8px;
  width: 24px;
  background: transparent;
  border-radius: 4px;
  border-style: none;
  line-height: 0;
  cursor: pointer;
`;
