import React from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@/shared/components/Button';
import { DEFAULT_CURRENCY } from '@/shared/constants';
import { srSpeak } from '@/shared/utils/srSpeak';

import type { TModifier } from '../types';

interface IProps {
  modifier: TModifier;
  parenthandler: (modifier: TModifier, callback?: VoidFunction) => void;
}

export const YesNoModifier: React.FC<IProps> = ({ modifier, parenthandler }) => {
  const { formatMessage, formatNumber } = useIntl();

  const messages = {
    yes: formatMessage({ id: 'Yes' }),
    no: formatMessage({ id: 'No' }),
    selectedNo: formatMessage(
      { id: 'GlobalComponents.Modifiers.YesNo.AriaLive.SelectedNo' },
      { 0: modifier.DisplayName },
    ),
    selectedYes: formatMessage(
      { id: 'GlobalComponents.Modifiers.YesNo.AriaLive.SelectedYes' },
      { 0: modifier.DisplayName },
    ),
  };

  const handler = (select: Nullable<string>): void => {
    const result = { ...modifier, SelectedVariant: select };
    srSpeak(`${modifier.DisplayName} ${select ? messages.yes : messages.no}`);
    parenthandler(result);
  };

  const isYes =
    modifier.SelectedVariant &&
    (modifier.SelectedVariant === 'yes' || modifier.SelectedVariant === 'on');

  if (!modifier) return null;
  return (
    <>
      <Button
        margin="8px 8px 0 0"
        width="max-content"
        padding="0 16px"
        fontWeight="normal"
        isDark={!isYes}
        click={() => {
          handler(null);
          srSpeak(messages.selectedNo);
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: messages.no }} />
      </Button>
      <Button
        margin="8px 8px 0 0"
        width="max-content"
        padding="0 16px"
        fontWeight="normal"
        isDark={!!isYes}
        click={() => {
          handler('yes');
          srSpeak(messages.selectedYes);
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: messages.yes }} />{' '}
        {!modifier.HidePrice && (
          <span>
            {formatNumber(modifier.ModifierPrice, {
              style: 'currency',
              currency: DEFAULT_CURRENCY,
            })}
          </span>
        )}
      </Button>
    </>
  );
};
