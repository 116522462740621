import { useDispatch, useSelector } from 'react-redux';

import { Action, Dispatch } from '@reduxjs/toolkit';

export const useAppDispatch = <T = undefined>(): T & Dispatch<Action> => {
  return useDispatch();
};

export const useAppSelector = <T = undefined, K = undefined>(selector: (state: T) => K): K => {
  return useSelector(selector);
};
