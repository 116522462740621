import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { AmountCalories, NutritionalTree } from '@/shared/components/AmountCalories';
import { Underline } from '@/shared/components/Underline';

import {
  ItemInfo,
  ModalProductDescription,
  ModalProductDescriptionItemDetails,
  ModalProductDisclaimer,
  ModalProductServingSize,
} from '../parts';

interface IProps {
  productInfo: {
    NutritionalTree: NutritionalTree;
    ServingSize: Nullable<string>;
    ServingUnit: Nullable<string>;
    IngredientStatement: Nullable<string>;
    AllergenStatement: Nullable<string>;
  };
  calcComponent?: ReactNode;
  disclaimerBold?: string;
}
const Nutrition: React.FC<IProps> = ({ productInfo, calcComponent, disclaimerBold }) => {
  const { formatMessage } = useIntl();

  const messages = {
    servingSize: formatMessage({ id: 'ServingSize' }),
    ingredients: formatMessage({ id: 'Ingredients' }),
    disclaimer: formatMessage({ id: 'Disclaimer' }),
    disclaimerBold: disclaimerBold || formatMessage({ id: 'DisclaimerBold' }),
    amountCaloriesTitle: formatMessage({
      id: 'Menus.Filters.MealCalculator.Labels.AmountCalories',
    }),
  };

  const calories = productInfo.NutritionalTree;
  return (
    <>
      <ModalProductDescription className="ModalProductDescription">
        <ModalProductDescriptionItemDetails>
          <ItemInfo className="ItemInfo" style={{ padding: 0 }}>
            {calcComponent}
          </ItemInfo>
        </ModalProductDescriptionItemDetails>
      </ModalProductDescription>

      <ModalProductServingSize className="ModalProductServingSize">
        <b
          dangerouslySetInnerHTML={{
            __html: messages.servingSize,
          }}
        />{' '}
        {productInfo?.ServingSize} {productInfo?.ServingUnit}
      </ModalProductServingSize>
      <Underline />
      {calories && calories.length && (
        <AmountCalories calories={calories} title={messages.amountCaloriesTitle} />
      )}

      <ModalProductDisclaimer className="ModalProductDisclaimer">
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `<b>${messages.ingredients}</b>: ${productInfo?.IngredientStatement}
              ${productInfo?.AllergenStatement}`,
          }}
        />
      </ModalProductDisclaimer>
      <ModalProductDisclaimer
        margin="0"
        className="disclaimer"
        dangerouslySetInnerHTML={{
          __html: messages.disclaimer,
        }}
      />
      <ModalProductDisclaimer
        margin="0"
        fontWeight="bold"
        className="disclaimerBold"
        dangerouslySetInnerHTML={{
          __html: messages.disclaimerBold,
        }}
      />
    </>
  );
};
export default Nutrition;
