import React, { memo, useEffect, useState } from 'react';

import { MODAL_ELEMENTS } from '@/shared/constants';
import { noop } from '@/shared/utils/noop';

import ModalService from '../action';
import { MODAL_ARIA_LABELLEDBY } from '../constants';
import type { TModalState } from '../type';
import { ModalCore } from './parts';

const defaultModalState = {
  close: noop,
  isOpen: false,
};

interface IProps {
  style?: ReactModal.Styles;
  className?: string | ReactModal.Classes;
  htmlOpenClassName?: string;
  eventName?: string;
  appElement: string;
  parentSelector?: string;
}

const Modal: React.FC<IProps> = ({
  style,
  className = 'ModalCore',
  htmlOpenClassName = 'overflow_hidden',
  eventName,
  appElement,
  parentSelector = MODAL_ELEMENTS.ROOT_MODAL,
}) => {
  const [modal, setModal] = useState<TModalState>(defaultModalState);

  useEffect(() => {
    const unsubscribe = ModalService.on(({ component, props }) => {
      setModal({
        component,
        props,
        close: () => setModal(defaultModalState),
        isOpen: true,
      });
    }, eventName);

    return unsubscribe;
  }, [eventName]);

  const defaultStyle: ReactModal.Styles = {
    overlay: {
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      overflow: 'auto',
      inset: '0px',
      zIndex: 99,
      ...style?.overlay,
    },
    content: style?.content,
  };

  const ariaData = {
    labelledby: modal.props?.ariaLabelledby || MODAL_ARIA_LABELLEDBY,
    describedby: modal.props?.ariaDescribedby,
  };

  const ModalComponent = modal.component;

  const getParenSelector = (): HTMLElement => document.getElementById(parentSelector)!;

  const handlerRequestClose = (): void => {
    modal.props?.onRequestClose?.();
    modal.close();
  };

  return (
    <ModalCore
      isOpen={modal.isOpen}
      onRequestClose={handlerRequestClose}
      style={defaultStyle}
      aria={ariaData}
      className={className}
      appElement={document.getElementById(appElement)!}
      parentSelector={getParenSelector}
      bodyOpenClassName="overflow_hidden"
      htmlOpenClassName={htmlOpenClassName}
      shouldReturnFocusAfterClose={modal.props?.shouldReturnFocusAfterClose}
    >
      {ModalComponent && <ModalComponent {...modal.props} close={modal.close} />}
    </ModalCore>
  );
};

export default memo(Modal);
