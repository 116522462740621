import styled, { css } from 'styled-components';

import type { IDefaultTheme } from '@/shared/themes/types';

export const DescriptionStyle = styled.div`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    width: 98%;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;

    span {
      color: ${theme.defaultPrimaryColor};
    }
  `}
`;

export const BlockStyle = styled.div`
  margin: ${({ mt = '8px', mb = '8px' }: { mt?: string; mb?: string }) => `${mt} 0 ${mb} 0`};
  position: relative;
`;
export const BlockButtonStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
`;
export const TextAreaStyle = styled.textarea`
  width: 100%;
  height: 102px;
  margin: 8px 0 0;
  box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #000;
  background-color: #fff;
`;

export const LocationOne = styled.p`
  font-size: 18px;
  color: #000;
`;

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '767px',
};

export const WarningDiv = styled.div`
  height: 32px;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.defaultPrimaryColor};
  width: fit-content;
  color: white;
  margin-bottom: 20px;

  @media (max-width: ${size.tablet}) {
    height: auto;
    display: flex;
  }
`;

export const WarningContainer = styled.div`
  display: inline-block;
  position: relative;
  top: -3px;
`;

export const Input = styled.input`
  height: 0;
  width: 0;
  position: absolute;
  left: -9999px;
`;
export const SubHeader = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
`;

export const TitleTimes = styled.legend`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    width: 98%;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;

    span:not(.legend) {
      color: ${theme.defaultPrimaryColor};
    }
  `}
`;
