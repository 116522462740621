import styled, { css } from 'styled-components';

import type { ICheckoutTheme } from '@/shared/themes/types';

export const SummaryDiv = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: #f7f7f7;
  border: 1px solid #cccccc;
  padding: 16px;
  border-radius: 4px;
`;
export const SummaryTable = styled.table`
  width: 100%;
  height: unset;
  margin-top: 24px;
  margin-bottom: 12px;
  table-layout: fixed;
`;

export const SummaryTableHeaderRow = styled.tr`
  ${({ theme }: { theme: ICheckoutTheme }) => css`
    display: flex;
    height: unset;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 8px;
    font-size: ${theme.fontSizes.small};
    font-weight: ${theme.fontWeight.normal};
  `}
`;

export const SummaryTableRow = styled(SummaryTableHeaderRow)`
  ${() => css`
    margin-bottom: 16px;
  `}
`;

export const SummaryTableFooterRow = styled(SummaryTableHeaderRow)`
  ${() => css`
    margin-bottom: 0;
  `}
`;

export const QtyCellHeader = styled.th`
  ${({ theme }: { theme: ICheckoutTheme }) => css`
    font-size: ${theme.fontSizes.small};
    font-weight: ${theme.fontWeight.normal};
    width: 30px;
    justify-self: flex-start;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 0;
  `}
`;

export const QtyCell = styled.td`
  ${({ theme }: { theme: ICheckoutTheme }) => css`
    font-size: ${theme.fontSizes.large};
    font-weight: ${theme.fontWeight.normal};
    width: 30px;
    justify-self: flex-start;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0;
  `}
`;

export const MainCellHeader = styled.th`
  ${({ theme }: { theme: ICheckoutTheme }) => css`
    font-size: ${theme.fontSizes.small};
    font-weight: ${theme.fontWeight.normal};
    padding: 0;
    margin: 8px;
    margin-top: 0;
    height: auto;
    width: calc(100% - 90px);
    padding-left: 5px;
    justify-self: center;
    justify-self: flex-start;
    text-align: left;
    align-items: center;
  `}
`;

export const MainCell = styled.th`
  width: calc(100% - 90px);
  justify-self: flex-start;
  text-align: left;
  align-items: center;
  margin: 0 8px;
  padding: 0;
`;

export const MainCellName = styled.div`
  ${({ theme }: { theme: ICheckoutTheme }) => css`
    font-size: ${theme.fontSizes.large};
    font-weight: ${theme.fontWeight.normal};
    white-space: initial;
  `}
`;

export const MainCellModifiers = styled.div`
  ${({ theme, width }: { theme: ICheckoutTheme; width: number }) => css`
    width: ${width}px;
    height: auto;
    font-size: ${theme.fontSizes.small};
    font-weight: ${theme.fontWeight.normal};
    margin-top: 8px;
    white-space: initial;
  `}
`;

export const PriceCellHeader = styled.th`
  ${({ theme }: { theme: ICheckoutTheme }) => css`
    width: 60px;
    justify-self: flex-end;
    text-align: right;
    font-size: ${theme.fontSizes.small};
    font-weight: ${theme.fontWeight.normal};
    padding: 0;
    height: auto;
  `}
`;

export const PriceCell = styled.td`
  ${({ theme }: { theme: ICheckoutTheme }) => css`
    width: fit-content;
    justify-self: flex-end;
    text-align: right;
    font-size: ${theme.fontSizes.medium};
    font-weight: ${theme.fontWeight.large};
    padding: 0;
  `}
`;

export const TotalRowName = styled.th`
  ${({ theme }: { theme: ICheckoutTheme }) => css`
    flex: 1 1 80%;
    justify-self: flex-start;
    text-align: right;
    font-size: ${theme.fontSizes.medium};
    font-weight: ${theme.fontWeight.normal};
    padding: 0;
    margin-bottom: 8px;
  `}
`;

export const TotalRowSum = styled.td<{ theme: ICheckoutTheme; isDiscount?: boolean }>`
  ${({ theme, isDiscount }) => css`
    flex: 1 0 24%;
    justify-self: flex-end;
    text-align: right;
    font-size: ${theme.fontSizes.medium};
    font-weight: ${theme.fontWeight.large};
    color: ${isDiscount ? 'red' : 'unset'};
    padding: 0;
    margin-bottom: 8px;

    ${theme.media.tablet} {
      flex: 1 0 14%;
    }
  `}
`;
