import React from 'react';

import { HeaderTabs, ItemTab, PanelTabs, WrapperTabs } from './parts';
import type { ITabsProps } from './types';

export const Tabs: React.FC<ITabsProps> = ({
  selectedIndex,
  onSelect,
  listTabs,
  isNoContent,
  className,
  marginForHeaderTabs,
  id,
}) => {
  if (!listTabs) return null;
  const header = listTabs.map((tab) => {
    return (
      <ItemTab
        className="ItemTab"
        tabIndex={tab.disabled || !tab.visible ? '-1' : '0'}
        aria-label={tab.ariaLabel}
        data-id={tab.id}
        key={tab.id}
        style={{
          display: tab.visible ? 'block' : 'none',
        }}
        // When using the ''disabled'' attribute on an element, the attribute "aria-disabled='true' " is automatically added,
        // which adds the text ''{tab name} tab unavailable'' when NVDA is spoken.
        // Bug on this 28990.
        onClick={(event) => {
          if (tab.disabled) event.stopPropagation();
        }}
      >
        {tab.name}
      </ItemTab>
    );
  });
  const content = listTabs.map((tab) => (
    <PanelTabs
      style={{ display: isNoContent ? 'none' : 'block' }}
      className="PanelTabs"
      key={tab.id}
    >
      {tab.panel}
    </PanelTabs>
  ));

  return (
    <WrapperTabs
      id={id}
      data-testid="global-tabs"
      className={className || 'WrapperTabs'}
      selectedIndex={selectedIndex}
      onSelect={onSelect}
    >
      <HeaderTabs margin={marginForHeaderTabs} className="HeaderTabs">
        {header}
      </HeaderTabs>

      {content}
    </WrapperTabs>
  );
};
