import React from 'react';

import { ImgComponent } from './parts';

interface IProps {
  width?: string;
  height?: string;
  alt?: string;
  margin?: string;
  mobMargin?: string;
  src: string;
  cbOnError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  className?: string;
  hidden?: boolean;
  borderRadius?: string;
  maxHeight?: string;
  maxWidth?: string;
  'data-testid'?: string;
}

const Img: React.FC<IProps> = ({
  width,
  height,
  alt = '',
  margin,
  mobMargin,
  src,
  cbOnError,
  className,
  hidden,
  borderRadius,
  maxHeight,
  maxWidth,
  'data-testid': dataTestId = 'img-component',
}) => {
  const handlerOnError = (e: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    if (cbOnError) cbOnError(e);
  };
  if (hidden || !src) return null;
  return (
    <ImgComponent
      data-testid={dataTestId}
      loading="lazy"
      className={className || 'ImgComponent'}
      width={width}
      height={height}
      margin={margin}
      mobMargin={mobMargin}
      alt={alt}
      src={src}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      borderRadius={borderRadius}
      onError={handlerOnError}
    />
  );
};
export default Img;
