import styled, { css } from 'styled-components';

import { ButtonText } from '@/shared/components/Button';
import type { IDefaultTheme } from '@/shared/themes/types';

type TProductCardStyle = {
  theme: IDefaultTheme;
  isPadding?: boolean;
  activeBorder?: boolean;
  margin?: string;
};
export const ProductCardComponent = styled.li<TProductCardStyle>`
  ${({ theme, isPadding, activeBorder, margin }: TProductCardStyle) => css`
    background-color: #f7f7f7;
    height: inherit;
    overflow: hidden;
    padding: ${isPadding ? '0' : '0 0 8px 0'};
    border-radius: 4px;
    border: ${activeBorder ? `2px solid ${theme.defaultPrimaryColor}` : '1px solid #ccc'};
    position: relative;
    display: flex;
    flex: 0 1 ${theme.cardWidth}px;
    max-width: ${theme.cardWidth}px;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    margin: ${margin || ''};
    z-index: 0;

    ${theme.media.desktop} {
      flex: 0 1 ${theme.cardWidthFromMdToLg}px;
    }

    ${theme.media.largeDesktop} {
      flex: 0 1 ${theme.cardWidth}px;
    }
  `}
`;

export const UpperPart = styled.div`
  width: inherit;
  display: flex;
  flex-direction: column;
`;
export const LowerPart = styled.div`
  width: inherit;
`;

export const HeaderItemBtn = styled(ButtonText)`
  width: 100%;
  padding: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 1px 4px 0 rgb(51 51 51 / 10%);
  background-color: #ffffff;
  display: inline-flex;
  height: ${({ isHigh }: { isHigh: boolean }) => (isHigh ? '75px' : '54px')};

  :disabled {
    background-color: #ffffff;
  }
`;

export const HeaderItemName = styled.h3`
  ${({ theme, padding }: { theme: IDefaultTheme; padding?: string }) => css`
    justify-content: start;
    align-items: center;
    display: -webkit-box;
    font-weight: ${theme.fontWeight.normal};
    letter-spacing: normal;
    color: #000000;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    font-size: 16px;
    padding: ${padding || 0};
    margin: auto 8px;
    -webkit-line-clamp: 2;
  `}
`;

export const HeaderItemNameLink = styled.span`
  ${({ theme, toPrint }: { theme: IDefaultTheme; toPrint?: string }) => css`
    padding: 0;
    display: inline-flex;
    height: auto;
    max-height: 40px;
    color: ${theme.defaultPrimaryColor};
    margin: 0;
    overflow: hidden;
    font-size: ${toPrint ? '8px' : 'unset'};
    font-weight: normal;
  `}
`;

export const ItemCalories = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  margin: 0;
  background: #efefef;
  padding: 1px 8px 0 8px;
  position: relative;
  border-radius: 4px;
  white-space: pre;
  height: 24px;
`;
export const ItemPrice = styled.span`
  height: 24px;
  padding: 0 8px;
  border-radius: 4px;
  background: #efefef;
  font-size: 14px;
  font-weight: bold;
  margin-left: auto;
`;

export const ItemDetails = styled.div`
  float: left;
  padding-top: 0;
  margin: 0 0 0 8px;
`;

export const ItemContent = styled.p`
  display: -webkit-box;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 2.5em;
  flex: 1;
  margin: 16px 8px 0 8px;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: normal;
  color: #000000;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const FooterItem = styled.div`
  width: 100%;
  margin: 16px 0 0 0;
  min-height: 40px;
`;

export const ItemInfo = styled.div`
  ${({
    isDescription,
    IsDeemphasized,
  }: {
    isDescription: boolean;
    IsDeemphasized?: boolean;
  }) => css`
    min-height: ${isDescription ? '40px' : ''};
    font-size: 16px;
    color: #000000;
    width: 100%;
    padding: ${IsDeemphasized ? '16px 8px 8px 8px' : '16px 8px 0 8px'};
    display: flex;
    justify-content: start;
  `}
`;

export const ReorderButton = styled.button`
  width: 132px;
  height: 32px;
  padding: 4px 7px 4px 8px;
  border: 0px;
  border-radius: 3px;
  background-color: ${({ theme }: { theme: IDefaultTheme }) => theme.defaultPrimaryColor};
`;

export const OrderAgainText = styled.span`
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  display: inline-block;
  position: relative;
  top: -1px;
  float: right;
  color: #ffffff;
`;
