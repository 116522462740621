import React from 'react';

import MinusIcon from '../icons/minusIcon.svg';
import PlusIcon from '../icons/plusIcon.svg';

interface IProps {
  isPlus?: boolean;
}
const PlusOrMinus: React.FC<IProps> = ({ isPlus = false }) => {
  return isPlus ? (
    <PlusIcon className="r-w-4 r-h-4 r-m-auto r-fill-primary" />
  ) : (
    <MinusIcon className="r-w-4 r-h-4 r-m-auto r-fill-primary" />
  );
};
export default PlusOrMinus;
