import React, { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { PlusOrMinus } from '@/shared/components/Button';
import { isPropsEqual } from '@/shared/utils/props-comparison';

import { CounterComponent, CounterContent, CounterMinusOrPlus } from './parts';

interface IProps {
  click: (count: number) => void;
  minCount: number;
  maxCount: number;
  selectedProductQuantity: number;
}
const Counter: React.FC<IProps> = ({ click, minCount, maxCount, selectedProductQuantity }) => {
  const { formatMessage } = useIntl();

  const messages = {
    decreaseAriaLabel: formatMessage({
      id: 'GlobalComponents.Counter.Decrease.AriaLabel',
    }),
    quantityAriaLabel: formatMessage({
      id: 'GlobalComponents.Counter.Quantity.AriaLabel',
    }),
    increaseAriaLabel: formatMessage({
      id: 'GlobalComponents.Counter.Increase.AriaLabel',
    }),
  };
  const checkCount = (sum: number): number => {
    if (sum < minCount) return minCount;
    if (sum > maxCount) return maxCount;
    return sum;
  };

  const count = checkCount(selectedProductQuantity);

  useEffect(() => {
    // If the 'selectedProductQuantity' value initially does not fit the minimum or maximum value
    if (count !== selectedProductQuantity) click(checkCount(count));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
    if (Number.isNaN(+value)) return;

    const processedValue = checkCount(+value);
    if (processedValue !== count) click(processedValue);
  };

  const handleMinus = (): void => {
    click(checkCount(count - 1));
  };

  const handlePlus = (): void => {
    click(checkCount(count + 1));
  };

  return (
    <CounterComponent className="CounterComponent">
      <CounterMinusOrPlus
        aria-label={messages.decreaseAriaLabel}
        aria-disabled="false"
        aria-controls="react-button"
        role="button"
        onClick={handleMinus}
        disabled={count === minCount}
      >
        <PlusOrMinus />
      </CounterMinusOrPlus>
      <CounterContent
        name="counter-input"
        value={count}
        aria-label={messages.quantityAriaLabel}
        onChange={handleInputChange}
      />
      <CounterMinusOrPlus
        aria-label={messages.increaseAriaLabel}
        aria-disabled="false"
        aria-controls="react-button"
        role="button"
        onClick={handlePlus}
        disabled={count === maxCount}
      >
        <PlusOrMinus isPlus />
      </CounterMinusOrPlus>
    </CounterComponent>
  );
};

export default memo(Counter, isPropsEqual);
