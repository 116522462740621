import React from 'react';
import { useIntl } from 'react-intl';

import { ErrorText, Svg } from './parts';
import type { TErrorMessageProps } from './types';

const ErrorMessage: React.FC<TErrorMessageProps> = ({ error, children, isInfo, id }) => {
  const { formatMessage } = useIntl();
  if (!(children || error)) return null;

  const errorSvg = (
    <Svg role="presentation" aria-hidden="true" focusable="false">
      <g>
        <path
          transform="rotate(0,10,10) translate(4,4) scale(0.375,0.375)  "
          d="M9.9000245,6.000003L6.0000001,9.8999988 12.100037,16.000007 6.0000001,22.100002 9.9000245,25.999997 16,19.900018 22.100037,25.999997 26,22.100002 19.900024,16.000007 26,9.8999988 22.100037,6.000003 16,12.099997z M16,0C24.799988,0 32,7.2000005 32,16.000007 32,24.800016 24.799988,32.000001 16,32.000001 7.2000123,32.000001 8.3946347E-08,24.800016 0,16.000007 8.3946347E-08,7.2000005 7.2000123,0 16,0z"
        />
      </g>
    </Svg>
  );

  const infoSvg = (
    <Svg
      viewBox="-4 -3 28 28"
      role="presentation"
      aria-hidden="true"
      focusable="false"
      isInfo={isInfo}
    >
      <path
        transform="scale(0.75,0.75)"
        d="M14.299995,21.399963L14.299995,24.399963 17.699997,24.399963 17.699997,21.399963z M14.799995,6.6000061L14.299995,17.5 17.699997,17.5 17.199997,6.6000061z M16,0C24.799995,0 32,7.1999817 32,16 32,24.799988 24.799995,32 16,32 7.1999969,32 0,24.799988 0,16 0,7.1999817 7.1999969,0 16,0z"
      />
    </Svg>
  );

  const icon = isInfo ? infoSvg : errorSvg;

  if (children)
    return (
      <ErrorText id={id} isInfo={isInfo}>
        {icon} {children}
      </ErrorText>
    );

  const message = error?.message;
  const errorMessage =
    typeof message === 'string'
      ? message.length > 0 && formatMessage({ id: message })
      : message?.idIntl && formatMessage({ id: message.idIntl }, message?.values);

  return (
    <ErrorText id={id} isInfo={isInfo}>
      {icon}{' '}
      <span
        dangerouslySetInnerHTML={{
          __html: errorMessage as string,
        }}
      />
    </ErrorText>
  );
};

export default ErrorMessage;
