import styled, { css } from 'styled-components';

import { Underline as GlobalUnderline } from '@/shared/components/Underline';

export const AmountCaloriesComponent = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
export const InternalList = styled(Ul)`
  padding: 0;
  margin-left: 16px;
`;

export const NutritionCard = styled(Ul)`
  color: #000;
  font-weight: normal;
`;

export const AmountPerServing = styled.h3`
  color: #000;
  font-size: 14px;
  margin: 0 0 4px;
  line-height: 1.1;
  font-weight: normal;
`;

export const DescriptionProductOfComposition = styled.li`
  ${({ fontWeight = '600' }: { fontWeight?: string }) => css`
    font-size: 16px;
    font-weight: ${fontWeight};
    padding: 8px 0 0 0;
    span {
      float: right;
    }
  `}
`;

export const Underline = styled(GlobalUnderline)`
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #000;
`;

export const UnderlineInnerList = styled(Underline)`
  ${({ num = 1 }: { num?: number }) => css`
    width: calc(100% + (16px * ${num}));
    margin-left: calc(-16px * ${num});
  `}
`;
