import React, { useEffect, useMemo, useRef } from 'react';

const HiddenLoadingElement: React.FC = () => {
  const statusElement = useRef<HTMLDivElement>(null);
  const timerId = useMemo(
    () =>
      setInterval(() => {
        if (statusElement.current) {
          const withDot = `${statusElement.current.innerHTML}.`;
          const isDot = statusElement.current.innerHTML?.indexOf('.') !== -1;
          statusElement.current.innerHTML = isDot
            ? statusElement.current.innerHTML.replace('.', '')
            : withDot;
        }
      }, 4000),
    [],
  );

  useEffect(() => {
    return () => {
      clearInterval(timerId);
    };
  }, [timerId]);

  return (
    <div ref={statusElement} role="status" className="sr-only">
      Loading menu
    </div>
  );
};

export default HiddenLoadingElement;
