import { format, isSameMinute } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { IApiResponseGetPickUPTimes } from '@/shared/api/menu/types';
import { ButtonSecond } from '@/shared/components/Button';
import { Tabs } from '@/shared/components/Tabs';
import type { ITabsProps } from '@/shared/components/Tabs/types';
import { FORMAT_DATE_TIME, FULFILLMENT_METHODS } from '@/shared/constants';
import { ModalOne } from '@/shared/services/modal';
import type { TPortalModalComponent } from '@/shared/services/modal/type';

import BodyContentForModal from './blocks/BodyContentForModal';
import { DeliveryPart } from './blocks/DeliveryPart';
import { PickUpPart } from './blocks/PickUpPart';
import { TIME_LIST } from './constants';
import { BlockButtonStyle } from './parts';
import type { IProps, TOrderAheadValuesForm } from './types';

const ModalOrderAhead: TPortalModalComponent<IProps> = ({
  setActiveTab,
  tabIndexParent,
  close,
  OnlyShowCurrentDaysOnlineOrdering,
  OnlyAllowFutureDaysOnlineOrdering,
  isPickUpTab,
  isDeliveryTab,
  defaultPickUPTimes,
  fulfillment,
  orderMenuDate,
  setOrderMenuDate,
  setChoosenMeal,
  mode,
  setFulfillment,
  isSlotExpired = false,
  msgForSlotExpired,
  nameButton,
  basketName,
  locationId,
  storeIds,
  pickupTimeIncrement,
  menuCalendar,
  selectedTabIndex,
  preselectedLocation,
  preselectedTime,
  handlePickUpTimes,
  handleFetchMenu,
  isMealSelectDisabled = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, startTransition] = useTransition();
  const [isLoadingTime, setLoadingTime] = useState(false);
  const [pickUPTimes, setPickUPTimes] = useState(defaultPickUPTimes);

  const mealOptions =
    (!!pickUPTimes?.MenuPeriods &&
      pickUPTimes?.MenuPeriods.map((period) => {
        return { value: period.PeriodName, label: period.PeriodName, key: period.PeriodId };
      })) ||
    [];

  const { MMMDDYYYY, AMPM } = FORMAT_DATE_TIME;

  const { PICK_UP, DELIVERY } = FULFILLMENT_METHODS;
  const defaultMeal = pickUPTimes?.MealPeriodId
    ? mealOptions.find((meal) => Number(meal.key) === Number(pickUPTimes?.MealPeriodId))?.key
    : mealOptions[0]?.key || null;
  const defaultLocation = preselectedLocation || fulfillment.PickUpLocation;

  const methods = useForm<TOrderAheadValuesForm>({
    mode: 'onTouched',
    shouldUnregister: true,
    defaultValues: {
      location: defaultLocation,
      deliveryLocation: fulfillment.DeliveryLocation,
      meal: defaultMeal,
      date: orderMenuDate,
      time:
        preselectedTime ||
        (fulfillment.SelectedFulfillmentMethod === PICK_UP.name
          ? fulfillment.PickUpFulfillment?.PickupDateTime
          : fulfillment.DeliveryFulfillment?.DeliveryDateTime),
    },
  });

  const { handleSubmit, setValue, getValues, clearErrors } = methods;

  const [tabIndex, setTabIndex] = useState(selectedTabIndex);
  const { formatMessage } = useIntl();

  const messages = {
    StartOrder: formatMessage({ id: 'StartOrder' }),
    OrderAhead: formatMessage({ id: 'OrderAhead' }),
    ContinueOrder: formatMessage({ id: 'ContinueOrder' }),
    pickUpTab: formatMessage({ id: 'OrderAheadPickUpTab' }),
    deliveryTab: formatMessage({ id: 'OrderAheadDeliveryTab' }),
  };

  useEffect(() => {
    setValue('meal', defaultMeal || null);
    if (!pickUPTimes?.TimeSlots || pickUPTimes?.TimeSlots.length === 0) setValue('time', null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickUPTimes?.MealPeriodId]);

  const buttonNameInMenus = isSlotExpired ? messages.ContinueOrder : messages.StartOrder;
  const buttonName = nameButton && nameButton !== null ? nameButton : buttonNameInMenus;

  const showPickUpTab = typeof isPickUpTab === 'boolean' ? isPickUpTab : true;

  const showDeliveryTab = typeof isDeliveryTab === 'boolean' ? isDeliveryTab : true;

  const locationOptions =
    (!!pickUPTimes?.ListPickUpLocations &&
      pickUPTimes?.ListPickUpLocations.map((location, i) => {
        if (i === 0) return { value: location.Text, label: location.Text, isFixed: true };
        return { value: location.Text, label: location.Text };
      })) ||
    [];
  const locationOne =
    pickUPTimes?.ListPickUpLocations?.length === 1 ? pickUPTimes?.ListPickUpLocations[0].Text : '';

  useEffect(() => {
    if (locationOne) setValue('location', locationOne);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const times = useMemo(() => {
    if (!pickUPTimes?.TimeSlots) return [];

    return pickUPTimes?.TimeSlots.map((time) => ({
      title: format(new Date(time), AMPM),
      value: time,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickUPTimes?.TimeSlots]);

  useEffect(() => {
    const timeValue = getValues('time');
    const clearValueTime = (): void => setValue('time', null);
    if (isSlotExpired || times.length < 1) clearValueTime();

    const isSame = times.some((t) => isSameMinute(new Date(t.value), new Date(timeValue || '')));

    if (!isSame) clearValueTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSlotExpired, times]);

  const reqGetPickUPTimes = useCallback(
    ({
      date,
      customIndex = tabIndex,
      mealId,
      callback,
    }: {
      date?: string;
      customIndex?: number;
      mealId?: number;
      callback?: VoidFunction;
    }) => {
      const [orderDate, orderMeal] = getValues(['date', 'meal']);

      const handleCallback = (data: IApiResponseGetPickUPTimes['Result']): void => {
        clearErrors();
        setPickUPTimes(data);
        if (callback) callback();
      };

      const preparedData = {
        // Для всех
        locationId,
        date: date || orderDate,
        periodId: mealId || orderMeal,
        callback: handleCallback,
        // TODO: добавить в АПИ
        handleTurnOffLoading: () => {
          startTransition(() => setLoadingTime(false));
        },

        // Для Меню
        mode,
        fulfillmentMethod: customIndex,
        // Для Корзины
        storeIds,
        pickupTimeIncrement,
        basketName,
      };

      startTransition(() => setLoadingTime(true));
      handlePickUpTimes(preparedData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabIndex, isDeliveryTab, isPickUpTab],
  );
  const closeModal = useCallback(
    (key?: number) => {
      close();
      if (typeof tabIndexParent === 'number' && setActiveTab) {
        if (key && typeof key === 'number') setActiveTab(key);
        else setActiveTab(tabIndexParent);
      }
    },
    [close, setActiveTab, tabIndexParent],
  );

  const setFulfillmentInformation = (): void => {
    const [location, deliveryLocation, meal, date, time] = getValues([
      'location',
      'deliveryLocation',
      'meal',
      'date',
      'time',
    ]);
    if (tabIndex === PICK_UP.const) {
      // TODO: поправить метод при передаче как пропс
      setFulfillment({
        ...fulfillment,
        SelectedFulfillmentMethod: PICK_UP.name,
        PickUpFulfillment: {
          ...fulfillment.PickUpFulfillment,
          PickUpDate: time ? format(new Date(time), MMMDDYYYY) : '',
          PickUpTime: time ? format(new Date(time), AMPM) : '',
          PickupDateTime: time || '',
        },
        PickUpLocation: location,
        PeriodId: meal,
        SelectedDate: date,
      });
    } else {
      setFulfillment({
        ...fulfillment,
        SelectedFulfillmentMethod: DELIVERY.name,
        DeliveryFulfillment: {
          ...fulfillment.DeliveryFulfillment,
          DeliveryDate: time ? format(new Date(time), MMMDDYYYY) : '',
          DeliveryTime: time ? format(new Date(time), AMPM) : '',
          DeliveryDateTime: time || '',
          DeliveryLocation: deliveryLocation,
        },
        DeliveryLocation: deliveryLocation,
        PeriodId: meal,
        SelectedDate: date,
      });
    }

    const menuPeriod = pickUPTimes?.MenuPeriods?.find(
      (period) => Number(period.PeriodId) === Number(meal),
    );
    // TODO: поправить метод при передаче как пропс
    setChoosenMeal?.(
      menuPeriod ? { name: menuPeriod?.PeriodName, id: menuPeriod?.PeriodId } : null,
    );
  };

  const handlerStartOrder = (data: TOrderAheadValuesForm): void => {
    const { date } = data;
    const callback = (): void => {
      setFulfillmentInformation();
      const formatDate = date && new Date(date).toDateString();
      // TODO: поправить метод при передаче как пропс
      setOrderMenuDate(formatDate);
      closeModal(1);
    };

    const pickupLocation = tabIndex ? data.deliveryLocation : data.location;
    const preparedData = {
      mode,
      customPeriodId: data.meal,
      customDate: date,
      time: data.time,
      updatedDateTime: data.time,
      fulfillmentMode: tabIndex,
      fulfillmentMethod: tabIndex,
      pickupLocation,
      // TODO: переименновать в API
      basket: basketName,
      callback,
    };
    handleFetchMenu(preparedData);
  };

  const handlerErrorForm: SubmitErrorHandler<TOrderAheadValuesForm> = (errors) => {
    console.log('Errors: ', errors);

    const isFieldsFilledIn = Object.keys(errors).length === 1 && errors?.time;

    if (isFieldsFilledIn) {
      const timeList = document.getElementById(TIME_LIST);
      if (timeList) {
        const firstInput = timeList.children[0] as HTMLElement;
        if (firstInput) firstInput.focus();
      }
    }
  };
  const handlerOnSelectTab = useCallback(
    (index) => {
      setTabIndex(index);
      reqGetPickUPTimes({ customIndex: index });
    },
    [reqGetPickUPTimes],
  );

  const tabsOptions: ITabsProps = {
    selectedIndex: tabIndex,
    onSelect: handlerOnSelectTab,
    isNoContent: false,
    listTabs: [
      {
        id: PICK_UP.name,
        name: (
          <span
            dangerouslySetInnerHTML={{
              __html: messages.pickUpTab,
            }}
          />
        ),
        ariaLabel: messages.pickUpTab,
        visible: showPickUpTab,
        panel: (
          <BodyContentForModal
            mealOptions={mealOptions}
            reqGetPickUPTimes={reqGetPickUPTimes}
            OnlyShowCurrentDaysOnlineOrdering={OnlyShowCurrentDaysOnlineOrdering}
            OnlyAllowFutureDaysOnlineOrdering={OnlyAllowFutureDaysOnlineOrdering}
            isSlotExpired={isSlotExpired}
            msgForSlotExpired={msgForSlotExpired}
            getMenuCalendar={menuCalendar}
            isDisabled={isMealSelectDisabled}
          >
            <PickUpPart
              locations={locationOptions}
              locationOne={locationOne}
              times={times}
              isSlotExpired={isSlotExpired}
              loading={isLoadingTime}
            />
          </BodyContentForModal>
        ),
        disabled: tabIndex === PICK_UP.const,
      },
      {
        id: DELIVERY.name,
        name: (
          <span
            dangerouslySetInnerHTML={{
              __html: messages.deliveryTab,
            }}
          />
        ),
        ariaLabel: messages.deliveryTab,
        visible: showDeliveryTab,
        panel: (
          <BodyContentForModal
            mealOptions={mealOptions}
            reqGetPickUPTimes={reqGetPickUPTimes}
            OnlyShowCurrentDaysOnlineOrdering={OnlyShowCurrentDaysOnlineOrdering}
            OnlyAllowFutureDaysOnlineOrdering={OnlyAllowFutureDaysOnlineOrdering}
            isSlotExpired={isSlotExpired}
            msgForSlotExpired={msgForSlotExpired}
            getMenuCalendar={menuCalendar}
            isDisabled={isMealSelectDisabled}
          >
            <DeliveryPart times={times} isSlotExpired={isSlotExpired} loading={isLoadingTime} />
          </BodyContentForModal>
        ),
        disabled: tabIndex === DELIVERY.const,
      },
    ],
  };

  return (
    <FormProvider {...methods}>
      <ModalOne
        title={
          <span
            dangerouslySetInnerHTML={{
              __html: messages.OrderAhead,
            }}
          />
        }
        handlerClose={closeModal}
        footer={
          <BlockButtonStyle>
            <ButtonSecond
              width="190px"
              isDark
              click={handleSubmit(handlerStartOrder, handlerErrorForm)}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: buttonName,
                }}
              />
            </ButtonSecond>
          </BlockButtonStyle>
        }
      >
        <Tabs marginForHeaderTabs="0" {...tabsOptions} />
      </ModalOne>
    </FormProvider>
  );
};

export default ModalOrderAhead;
