import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styled from 'styled-components';

export const WrapperTabs = styled(Tabs)`
  margin-top: 16px;
  color: #000;

  .react-tabs__tab--selected {
    background-color: #000;
    color: #fff;
  }
`;
export const PanelTabs = styled(TabPanel)`
  padding: 0 32px;
`;
export const HeaderTabs = styled(TabList)`
  width: 100%;
  padding: 0 0 0 32px;
  margin: ${({ margin }: { margin?: string }) => margin || ''};
  display: inline-flex;
  border-bottom: 1px solid #000;
`;
export const ItemTab = styled(Tab)`
  list-style-type: none;
  margin: 0px 10px 0px 0px;
  border-radius: 4px 4px 0 0;
  height: auto;

  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  padding: 8px 16px;
  cursor: pointer;
  &:focus {
    outline: solid 2px;
    outline-offset: -4px;
  }
`;
