import format from 'date-fns/format';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ButtonSecond } from '@/shared/components/Button';
import { Counter } from '@/shared/components/Counter';
import { ProductAllergens, ProductImg } from '@/shared/components/Img';
import { Tabs } from '@/shared/components/Tabs';
import type { ITabsProps } from '@/shared/components/Tabs/types';
import {
  DEFAULT_CURRENCY,
  FORMAT_DATE_TIME,
  FULFILLMENT_METHODS,
  MODIFIER_TYPES,
} from '@/shared/constants';
import { ButtonClose, MODAL_ARIA_LABELLEDBY } from '@/shared/services/modal';
import { ModalDialog } from '@/shared/services/modal/components/parts';
import type { TPortalModalComponent } from '@/shared/services/modal/type';
import { PayPalLaterMessage } from '@/shared/services/paypal';

import { Order } from './blocks/Order';
import {
  ModalBody,
  ModalHeaderItem,
  ModalHeaderItemName,
  ModalProductDescriptionContent,
  ModalProductFooter,
  OrderDate,
  ProductDescriptionDiv,
  WrapperButtons,
} from './parts';
import type { IProps, TDetailsPropForHandler, TProductDataForCart } from './types';
import { getAmount, sendGaEventProductDetailOpen } from './utils';

const ModalProduct: TPortalModalComponent<IProps> = ({
  product,
  nameButton,
  fulfillment,
  productDetailsWithModifiers,
  close,
  allowGA = false,
  isOrderAllowed = false,
  nutritionComponent,
  isOpenNutritionalInformation = false,
  hideProductsShortDescription = false,
  handlerFooter,
  handlerClose,
  menuCalendar,
}) => {
  const { PICK_UP, DELIVERY } = FULFILLMENT_METHODS;

  const [tabIndex, setTabIndex] = useState(isOrderAllowed ? PICK_UP.const : DELIVERY.const);
  const [warningMsg, setWarningMsg] = useState<string | undefined>(undefined);
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const setShowWarningMsg = (msg: string | null): void => {
    const text = msg?.trim();
    setWarningMsg(text);
    if (text)
      modalHeaderRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
  };

  const method = fulfillment?.SelectedFulfillmentMethod;
  const isPickUp = method === PICK_UP.name;

  const recipientInstructions = isPickUp
    ? fulfillment?.PickUpFulfillment.RecipientInstructions
    : fulfillment?.DeliveryFulfillment.RecipientInstructions;

  const [dataForCart, setDataForCart] = useState<TProductDataForCart>({
    Product: {
      Id: productDetailsWithModifiers?.Product?.Id,
      CatalogId: productDetailsWithModifiers?.Product?.CatalogId,
      StoreId: productDetailsWithModifiers?.Product?.StoreId,
      ProductType: productDetailsWithModifiers?.Product?.ProductType,
      ImageUrl: productDetailsWithModifiers?.Product?.ImageUrl,
      Modifiers: productDetailsWithModifiers?.Product?.Modifiers,
    },
    Configuration: productDetailsWithModifiers?.Configuration,
    UserOrderingInfo: productDetailsWithModifiers?.UserOrderingInfo,
    RecipientInstructions: recipientInstructions || '',
    PickupTimeIncrement: productDetailsWithModifiers?.PickUpFulfillment.PickupTimeIncrement,
    StoreId: productDetailsWithModifiers?.StoreId,
    DeliveryLeadTimeFrame: productDetailsWithModifiers?.DeliveryLeadTimeFrame,
    DeliveryLeadTime: productDetailsWithModifiers?.DeliveryLeadTime,
  });

  const minCount = productDetailsWithModifiers?.Product?.MinQuantity || 1;
  const maxCount = productDetailsWithModifiers?.Product?.MaxQuantity || 999;
  const counter = dataForCart?.UserOrderingInfo?.SelectedProductQuantity || 1;

  const { formatMessage, formatNumber } = useIntl();

  const messages = {
    orderTab: formatMessage({ id: 'Modals.ModalProduct.Tabs.Order' }),
    nutritionalTab: formatMessage({ id: 'Modals.ModalProduct.Tabs.Nutrition' }),
  };

  const formMethods = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    shouldUnregister: true,
    shouldUseNativeValidation: true,
  });

  const { handleSubmit } = formMethods;

  useEffect(() => {
    if (!isOrderAllowed && allowGA && isOpenNutritionalInformation) {
      sendGaEventProductDetailOpen(product); // now only Menus
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickClose = (): void => {
    close();
    handlerClose?.();
  };

  const getStringType = (num: number): string | undefined => {
    const listTypes = Object.values(MODIFIER_TYPES);
    return listTypes.find((type) => type.const === num)?.name;
  };

  const handleClick = (): void => {
    const modifiers = dataForCart.Product?.Modifiers?.map((modifier) => {
      return { ...modifier, ModifierType: getStringType(modifier.ModifierType) };
    });
    const updateData = {
      ...dataForCart,
      Product: {
        ...dataForCart.Product,
        Modifiers: modifiers,
      },
    };
    if (handlerFooter) {
      setShowWarningMsg(null);
      handlerFooter(updateData as TDetailsPropForHandler, close, setShowWarningMsg);
    }
  };

  const handlerSelectedProductQuantity = useCallback(
    (count: number) => {
      setDataForCart({
        ...dataForCart,
        UserOrderingInfo: {
          ...dataForCart.UserOrderingInfo,
          SelectedProductQuantity: count,
        },
      });
    },
    [dataForCart],
  );

  const orderDate = isPickUp
    ? fulfillment?.PickUpFulfillment.PickUpDate
    : fulfillment?.DeliveryFulfillment.DeliveryDate;

  const formatOrderDate = orderDate ? format(new Date(orderDate), FORMAT_DATE_TIME.eeeMMDD) : '';
  const tabsOptions: ITabsProps = {
    selectedIndex: tabIndex,
    onSelect: (index) => {
      setTabIndex(index);

      if (index === 1 && allowGA && isOpenNutritionalInformation) {
        sendGaEventProductDetailOpen(product); // only Menus
      }
    },
    isNoContent: false,
    listTabs: [
      {
        id: 'order',
        name: (
          <div id="modal_product_tab">
            <span
              dangerouslySetInnerHTML={{
                __html: messages.orderTab,
              }}
            />{' '}
            <OrderDate>{formatOrderDate}</OrderDate>
          </div>
        ),
        visible: isOrderAllowed,
        ariaLabel: `${messages.orderTab} ${formatOrderDate}`,
        disabled: !isOpenNutritionalInformation || (isOpenNutritionalInformation && tabIndex === 0),
        panel: (
          <Order
            recipientInstructions={dataForCart?.RecipientInstructions}
            warningMsg={warningMsg}
            modifiers={dataForCart?.Product?.Modifiers}
            isShowSpecialInstructions={
              !!productDetailsWithModifiers?.PickUpFulfillment?.ShowSpecialInstructions
            }
            setDataForCart={setDataForCart}
            menuCalendar={menuCalendar}
          />
        ),
      },
      {
        // only Menus and Manage Favorite List
        id: 'nutrition',
        name: (
          <span
            id="modal_product_tab"
            dangerouslySetInnerHTML={{
              __html: messages.nutritionalTab,
            }}
          />
        ),
        ariaLabel: messages.nutritionalTab,
        visible: isOpenNutritionalInformation,
        disabled: !isOrderAllowed || (isOrderAllowed && tabIndex === 1),
        panel: isOpenNutritionalInformation ? nutritionComponent : null,
      },
    ],
  };

  const dietaryInformation =
    product.DietaryInformation || productDetailsWithModifiers?.Product.DietaryInformation; // from Cart

  const amount = getAmount(product.CurrentPrice ?? 0, dataForCart.Product.Modifiers, counter);
  const ProductDescription = (
    <ProductDescriptionDiv>
      <ModalProductDescriptionContent className="ModalProductDescriptionContent">
        {/* // only show in Menus  */}
        {!hideProductsShortDescription ? product.Description || product.ShortDescription : null}
      </ModalProductDescriptionContent>
      {dietaryInformation && <ProductAllergens dietaryInformation={dietaryInformation!} />}
    </ProductDescriptionDiv>
  );

  const isPayPalEnabled =
    productDetailsWithModifiers &&
    typeof productDetailsWithModifiers?.Product?.PayPalEnabled === 'boolean'
      ? productDetailsWithModifiers?.Product.PayPalEnabled
      : product?.PayPalEnabled;
  return (
    <FormProvider {...formMethods}>
      <ModalDialog>
        <ButtonClose handlerClose={handleClickClose} />
        <ModalHeaderItem className="ModalHeaderItem" ref={modalHeaderRef}>
          {product?.ImageUrl && (
            <ProductImg
              src={product.ImageUrl}
              alt={product?.MarketingName || product?.DisplayName}
            />
          )}
          <ModalHeaderItemName className="ModalHeaderItemName" id={MODAL_ARIA_LABELLEDBY}>
            {product?.MarketingName || product?.DisplayName}
          </ModalHeaderItemName>
        </ModalHeaderItem>

        <ModalBody className="ModalBodyItem">
          {ProductDescription}
          <Tabs {...tabsOptions} />
        </ModalBody>

        {isOrderAllowed && (
          <ModalProductFooter aria-live="polite">
            <Counter
              click={handlerSelectedProductQuantity}
              minCount={minCount}
              maxCount={maxCount}
              selectedProductQuantity={+counter}
            />

            <WrapperButtons>
              {isPayPalEnabled && (
                <PayPalLaterMessage
                  data-pp-message
                  data-pp-layout="text"
                  data-pp-placement="product"
                  data-pp-text-color="black"
                  data-pp-logo-type="primary"
                  data-pp-logo-position="left"
                  data-pp-style-text-align="right"
                  data-pp-style-text-size="14"
                  data-pp-amount={amount}
                />
              )}
              <ButtonSecond
                disabled={!counter}
                isDark
                className="AddOrUpdateToCart"
                click={handleSubmit(handleClick)}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: nameButton || '',
                  }}
                />{' '}
                <span style={{ marginLeft: '16px' }}>
                  {formatNumber(amount, {
                    style: 'currency',
                    currency: DEFAULT_CURRENCY,
                  })}
                </span>
              </ButtonSecond>
            </WrapperButtons>
          </ModalProductFooter>
        )}
      </ModalDialog>
    </FormProvider>
  );
};
export default ModalProduct;
