import React, { PropsWithChildren } from 'react';

type ErrorHandler = (error: Error, info: React.ErrorInfo) => void;
type ErrorHandlingComponent<Props> = (
  props: PropsWithChildren<Props>,
  error?: Error,
) => React.ReactNode;

type ErrorState = { error?: Error };

export default function ErrorBoundary<Props extends object>(
  component: ErrorHandlingComponent<Props>,
  errorHandler?: ErrorHandler,
): React.ComponentType<Props> {
  return class WrapperErrorBoundary extends React.Component<Props, ErrorState> {
    constructor(props: Props | Readonly<Props>) {
      super(props);
      this.state = {
        error: undefined,
      };
    }

    static getDerivedStateFromError(error: Error): { error: Error } {
      return { error };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo): void {
      if (errorHandler) {
        errorHandler(error, info);
      }
    }

    render(): React.ReactNode {
      return component(this.props, this.state.error);
    }
  };
}
