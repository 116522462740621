import React from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@/shared/components/Button';
import { DEFAULT_CURRENCY } from '@/shared/constants';
import { srSpeak } from '@/shared/utils/srSpeak';

import { Wrapper } from '../parts';
import type { TModifier } from '../types';

interface IProps {
  modifier: TModifier;
  parenthandler: (modifier: TModifier, callback?: VoidFunction) => void;
}

export const SelectManyModifier: React.FC<IProps> = ({ modifier, parenthandler }) => {
  const { formatNumber, formatMessage } = useIntl();
  const messages = {
    select: formatMessage({ id: 'GlobalComponents.Modifiers.SelectMany.AriaLive.IsSelect' }),
    notSelect: formatMessage({ id: 'GlobalComponents.Modifiers.SelectMany.AriaLive.IsNotSelect' }),
  };
  const variants = modifier?.Variants;
  if (!variants) return null;
  const handler = (idVariant: string): void => {
    const newVariants = variants.map((variant) =>
      variant.VariantId === idVariant ? { ...variant, IsChecked: !variant.IsChecked } : variant,
    );

    const result = { ...modifier, Variants: newVariants };

    parenthandler(result);
  };

  const list = variants.map((item) => (
    <Button
      key={item.VariantId}
      margin="8px 8px 0 0"
      width="max-content"
      padding="0 16px"
      fontWeight="normal"
      isDark={item.IsChecked}
      click={() => {
        handler(item.VariantId);
        srSpeak(`${item.VariantName} ${!item.IsChecked ? messages.select : messages.notSelect}`);
      }}
    >
      {item.VariantName}{' '}
      {!item.HidePrice && (
        <span>
          ({formatNumber(item.VariantPrice, { style: 'currency', currency: DEFAULT_CURRENCY })})
        </span>
      )}
    </Button>
  ));

  return <Wrapper aria-label={modifier.DisplayName}>{list}</Wrapper>;
};
