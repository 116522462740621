import styled, { css } from 'styled-components';

import type { IDefaultTheme } from '@/shared/themes/types';

export const CounterComponent = styled.div`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    display: flex;
    gap: 1px;
    width: 120px;
    height: 40px;
    padding: 0;
    border-radius: 2px;
    background-color: #fff;
    margin: 0;
    font-size: 16px;
    text-align: center;
    color: ${theme.defaultPrimaryColor};
  `}
`;

export const CounterMinusOrPlus = styled.button`
  flex: 1 1 30%;
  border: 0 solid;
  background-color: transparent;
  border-radius: 4px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CounterContent = styled.input`
  background-color: transparent;
  width: 30%;
  border: 0 solid;
  text-align: center;
  color: #000;
`;
