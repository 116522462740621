export const TIME_LIST = 'time-list';
type TDefaultRule = {
  required: {
    value: boolean;
    message: {
      idIntl: string;
      values: {
        0: string;
      };
    };
  };
};
const defaultRules = (label: string, idIntl = 'Required'): TDefaultRule => ({
  required: {
    value: true,
    message: {
      idIntl,
      values: {
        0: label,
      },
    },
  },
});
export const ORDER_AHEAD_RULES = {
  LOCATION: (label: string, idIntl?: string) => {
    return defaultRules(label, idIntl);
  },
  DELIVERY_LOCATION: (label: string, idIntl?: string) => {
    return defaultRules(label, idIntl);
  },
  DATE: (label: string, idIntl?: string) => {
    return defaultRules(label, idIntl);
  },
  MEAL: (label: string, idIntl?: string) => {
    return defaultRules(label, idIntl);
  },
  TIME: (label: string, idIntl?: string) => {
    return defaultRules(label, idIntl);
  },
};
