import { PREFIX_URL_API } from '@/shared/constants';
import { IBasket } from '@/shared/types/checkout';
import { apiClient } from '@/shared/utils/apiClient';

import type {
  IApiResponseAddCreditCardPayment,
  IApiResponseApplyPromoCode,
  IApiResponseApplyRewards,
  IApiResponseCompletePurchase,
  IApiResponseConfigForPayPal,
  IApiResponseDeleteAddedCreditCard,
  IApiResponseDeleteAppliedReward,
  IApiResponseGetRewardsToApply,
  IApiResponseLigthboxOnApproval,
  IApiResponseUpdateBasket,
  TApiAddCreditCardPayment,
  TApiApplyPromoCode,
  TApiApplyRewards,
  TApiCompletePurchase,
  TApiConfigForPayPal,
  TApiDeleteAddedCreditCard,
  TApiDeleteAppliedReward,
  TApiGetRewardsToApply,
  TApiLigthboxOnApproval,
  TApiRefresh,
  TApiRegisterClient,
  TApiRemovePromoCode,
  TApiUpdateBasket,
  TApiUpdateBasketPhoneNumber,
} from './types';

export const applyPromoCode: TApiApplyPromoCode = (payload) => {
  const url = `${PREFIX_URL_API.BASKET}/ApplyPromoCode`;
  return apiClient.post<IApiResponseApplyPromoCode>(url, payload);
};
export const removePromoCode: TApiRemovePromoCode = (payload) => {
  const url = `${PREFIX_URL_API.BASKET}/RemovePromoCode`;
  return apiClient.post<IApiResponseApplyPromoCode>(url, payload);
};

export const completePurchase: TApiCompletePurchase = (payload) => {
  const url = `${PREFIX_URL_API.BASKET}/CompletePurchase`;
  return apiClient.post<IApiResponseCompletePurchase>(url, payload);
};

export const ligthboxOnApproval: TApiLigthboxOnApproval = (payload) => {
  const url = `${PREFIX_URL_API.BASKET}/LightBoxOnApproval`;
  return apiClient.post<IApiResponseLigthboxOnApproval>(url, payload);
};

export const addCreditCardPayment: TApiAddCreditCardPayment = (payload) => {
  const url = `${PREFIX_URL_API.BASKET}/AddCreditCardPayment`;
  return apiClient.post<IApiResponseAddCreditCardPayment>(url, payload);
};
export const deleteAddedCreditCard: TApiDeleteAddedCreditCard = (payload) => {
  const url = `${PREFIX_URL_API.BASKET}/RemoveCreditCard`;
  return apiClient.post<IApiResponseDeleteAddedCreditCard>(url, payload);
};
export const updateBasket: TApiUpdateBasket = (payload) => {
  const url = `${PREFIX_URL_API.BASKET}/UpdateBasket`;
  return apiClient.post<IApiResponseUpdateBasket>(url, payload);
};

export const deleteAppliedReward: TApiDeleteAppliedReward = (id, payload) => {
  const url = `${PREFIX_URL_API.LOYALTY}/rewards/${id}`;
  return apiClient.delete<IApiResponseDeleteAppliedReward>(url, payload);
};

export const applyRewards: TApiApplyRewards = (payload) => {
  const url = `${PREFIX_URL_API.LOYALTY}/rewards/apply`;
  return apiClient.post<IApiResponseApplyRewards>(url, payload);
};

export const getRewardsToApply: TApiGetRewardsToApply = (basketName) => {
  const url = `${PREFIX_URL_API.LOYALTY}/rewards?BasketName=${basketName}`;
  return apiClient.get<IApiResponseGetRewardsToApply>(url);
};

export const registerClient: TApiRegisterClient = (payload) => {
  const url = `${PREFIX_URL_API.LOYALTY}/accounts`;
  return apiClient.post<void>(url, payload);
};

export const updateBasketPhoneNumber: TApiUpdateBasketPhoneNumber = (payload) => {
  const url = `${PREFIX_URL_API.BASKET}/UpdateBasketPhoneNumber`;
  return apiClient.post<IBasket>(url, payload);
};

export const refresh: TApiRefresh = (payload) => {
  const url = `${PREFIX_URL_API.BASKET}/Refresh`;
  return apiClient.post<IBasket>(url, payload);
};

export const getConfigForPayPal: TApiConfigForPayPal = (locationId) => {
  const url = `${PREFIX_URL_API.API}/payments/paypal/gettoken?locationId=${locationId}`;
  return apiClient.get<IApiResponseConfigForPayPal>(url);
};
