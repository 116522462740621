import React, { memo } from 'react';

import { isPropsEqual } from '@/shared/utils/props-comparison';

import { NutritionalItem } from './NutritionalItem';
import { AmountCaloriesComponent, AmountPerServing, NutritionCard } from './parts';
import type { NutritionalTree } from './types';

interface IProps {
  calories: NutritionalTree;
  title: string;
}

const AmountCalories: React.FC<IProps> = ({ calories, title }) => {
  const list = calories.map((item) => (
    <React.Fragment key={item.Name}>
      <NutritionalItem item={item} />
    </React.Fragment>
  ));

  return (
    <AmountCaloriesComponent>
      <AmountPerServing className="AmountPerServing">{title.toUpperCase()}</AmountPerServing>
      <NutritionCard className="NutritionCard">{list}</NutritionCard>
    </AmountCaloriesComponent>
  );
};

export default memo(AmountCalories, isPropsEqual);
