import { isSameMinute } from 'date-fns';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ButtonCheckbox } from '@/shared/components/Button';

import { TIME_LIST } from '../constants';

const Wrapper = styled.div`
  display: flex;
  margin-top: 8px;
  flex-wrap: wrap;
  gap: 8px;
`;
interface IProps {
  times: {
    value: string;
    title: string;
  }[];
  selectedTime: Nullable<string>;
  handlerOnChange: (v: string) => void;
}
const SelectOne: FC<IProps> = ({ times, selectedTime, handlerOnChange }) => {
  if (!times) return null;
  const list = times.map((item) => (
    <ButtonCheckbox
      id={item.value}
      width="89px"
      height="36px"
      fontWeight="normal"
      isChecked={isSameMinute(new Date(item.value), new Date(selectedTime || ''))}
      click={() => {
        handlerOnChange(item.value);
      }}
      key={item.value}
    >
      {item.title}
    </ButtonCheckbox>
  ));

  return <Wrapper id={TIME_LIST}>{list}</Wrapper>;
};

export default SelectOne;
