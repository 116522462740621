import React, { useState } from 'react';

import Img from './Img';
import { ItemImg } from './parts';

interface IProps {
  src: Nullable<string>;
  alt: Nullable<string>;
  isShow?: boolean;
  'data-testid'?: string;
}
const ProductImg: React.FC<IProps> = ({
  src,
  alt,
  isShow = true,
  'data-testid': dataTestId = 'product-img',
}) => {
  const [isImgError, setImgError] = useState(false);

  if (!isShow || !src?.length || isImgError) return null;
  return (
    <ItemImg className="ItemImg">
      <Img
        src={src}
        alt={alt || ''}
        borderRadius="4px 0 0"
        cbOnError={() => setImgError(true)}
        data-testid={dataTestId}
      />
    </ItemImg>
  );
};
export default ProductImg;
