import { MODIFIER_TYPES } from '@/shared/constants';
import type { TCategories } from '@/shared/types/menu';

import type { TProductDataForCart } from './types';

export const sendGaEventProductDetailOpen = (product: {
  ProductId: string;
  MarketingName: Nullable<string>;
  DisplayName: Nullable<string>;
  Categories?: Nullable<TCategories>;
  CurrentPrice?: Nullable<number>;
}): void => {
  if (window?.gaGtag) {
    const productData = {
      item_id: product.ProductId,
      item_name: product.MarketingName || product.DisplayName,
      item_category: product.Categories?.[0].CategoryId ?? '',
      price: product?.CurrentPrice,
    };
    window.gaGtag.viewProduct(productData);
  }
};

export const getAmount = (
  productPrice: number,
  modifiersList?: TProductDataForCart['Product']['Modifiers'],
  count = 1,
): number => {
  const sum = [productPrice];
  modifiersList?.forEach((modifier) => {
    switch (modifier.ModifierType) {
      case MODIFIER_TYPES.SELECT_MANY.const:
        if (Array.isArray(modifier.Variants)) {
          sum.push(
            modifier.Variants.reduce((values, item) => {
              return item.IsChecked ? values + item.VariantPrice : values;
            }, 0),
          );
        }
        break;
      case MODIFIER_TYPES.SELECT_ONE.const:
        if (Array.isArray(modifier.Variants)) {
          sum.push(
            modifier.Variants.reduce((values, item) => {
              return item.IsChecked || item.VariantId === modifier?.SelectedVariant
                ? values + item.VariantPrice
                : values;
            }, 0),
          );
        }
        break;
      case MODIFIER_TYPES.YES_NO.const:
        sum.push(modifier.SelectedVariant ? modifier.ModifierPrice : 0);
        break;
      default:
        break;
    }
  });
  return sum.reduce((a, b) => a + b) * count;
};
