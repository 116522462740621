import React, { useState } from 'react';

import { ModalProductTextArea } from '../parts';

interface IProps {
  text?: string;
  handler: (value: string) => void;
  ariaLabel: string;
  placeholder: string;
}
export const TextArea: React.FC<IProps> = ({ text, handler, ariaLabel, placeholder }) => {
  const [value, setValue] = useState(text || '');

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setValue(event.target.value);
  };

  const onBlur = (): void => {
    handler(value);
  };

  return (
    <ModalProductTextArea
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      aria-label={ariaLabel}
      aria-disabled="false"
    />
  );
};
