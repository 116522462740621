import format from 'date-fns/format';
import React, { ForwardRefRenderFunction, PropsWithChildren, forwardRef, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { GroupBase, InputProps, components } from 'react-select';

import type { IApiResponseGetMenuCalendar } from '@/shared/api/menu/types';
import { DatePicker, DatePickerButton, useDatePicker } from '@/shared/components/DatePicker';
import { ErrorMessage } from '@/shared/components/ErrorMessage';
import type { TError } from '@/shared/components/ErrorMessage/types';
import { Required } from '@/shared/components/Required';
import { Select } from '@/shared/components/Select';
import type { ICustomOption } from '@/shared/components/Select/types';
import { FORMAT_DATE_TIME } from '@/shared/constants';

import { ORDER_AHEAD_RULES } from '../constants';
import { BlockStyle, DescriptionStyle, WarningContainer, WarningDiv } from '../parts';
import type { TOrderAheadValuesForm } from '../types';

interface IProps {
  mealOptions: {
    value: string;
    label: string;
    key: number;
  }[];
  reqGetPickUPTimes: (props: {
    date?: string;
    customIndex?: number;
    mealId?: number;
    callback?: VoidFunction;
  }) => void;

  msgForSlotExpired?: string;
  OnlyShowCurrentDaysOnlineOrdering: boolean;
  OnlyAllowFutureDaysOnlineOrdering: boolean;
  isSlotExpired?: boolean;
  getMenuCalendar: Nullable<IApiResponseGetMenuCalendar>;
  isDisabled: boolean;
}
const BodyContentForModal: React.FC<PropsWithChildren<IProps>> = ({
  children,
  mealOptions,
  reqGetPickUPTimes,
  OnlyShowCurrentDaysOnlineOrdering,
  OnlyAllowFutureDaysOnlineOrdering,
  isSlotExpired,
  msgForSlotExpired,
  getMenuCalendar,
  isDisabled,
}) => {
  const { control, setFocus, setValue } = useFormContext<TOrderAheadValuesForm>();

  const datePickerProps = useDatePicker({
    availableDates: getMenuCalendar?.AvailableDatesFoodOrder,
    numberDaysAfter: getMenuCalendar?.NumberDaysAfter,
    onlyShowCurrentDaysOnlineOrdering: OnlyShowCurrentDaysOnlineOrdering,
    onlyAllowFutureDaysOnlineOrdering: OnlyAllowFutureDaysOnlineOrdering,
  });

  const { MMDDYYYY } = FORMAT_DATE_TIME;
  const { formatMessage } = useIntl();

  const messages = {
    Date: formatMessage({ id: 'Date' }),
    Meal: formatMessage({ id: 'Meal' }),
    TimeNoLongerAvailable: formatMessage({ id: 'TimeNoLongerAvailable' }),
    required: formatMessage({ id: 'RequiredFields' }),
  };

  const handlerDatePicker = useCallback((getDate: Date, callback: VoidFunction) => {
    const date = format(getDate, MMDDYYYY);
    const cb = (): void => {
      setValue('date', date);
      callback?.();
    };

    reqGetPickUPTimes({ date, callback: cb });
    datePickerProps.setOpenDatePicker(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateRules = ORDER_AHEAD_RULES.DATE(messages.Date);
  const mealRules = ORDER_AHEAD_RULES.MEAL(messages.Meal);

  const Button = useCallback<
    ForwardRefRenderFunction<HTMLButtonElement, { disabled?: boolean; value?: string }>
  >(
    ({ disabled, value }, ref) => {
      return (
        <DatePickerButton
          value={value}
          disabled={disabled}
          isOpen={datePickerProps.isOpenDatePicker}
          setOpen={datePickerProps.setOpenDatePicker}
          customInputRef={ref}
        />
      );
    },
    [datePickerProps.isOpenDatePicker, datePickerProps.setOpenDatePicker],
  );

  const CustomInput = forwardRef(Button);

  return (
    <BlockStyle mt="16px" mb="32px">
      <BlockStyle
        mt="0"
        style={{
          fontSize: '14px',
        }}
      >
        <Required />
        <span
          dangerouslySetInnerHTML={{
            __html: messages.required,
          }}
        />
      </BlockStyle>

      {isSlotExpired && (
        <WarningDiv>
          <svg
            className="svg help-circle"
            viewBox="0 0 24 24"
            role="img"
            style={{ marginRight: '10px', top: '3px' }}
          >
            <path
              d="M14.299995,21.399963L14.299995,24.399963 17.699997,24.399963 17.699997,21.399963z M14.799995,6.6000061L14.299995,17.5 17.699997,17.5 17.199997,6.6000061z M16,0C24.799995,0 32,7.1999817 32,16 32,24.799988 24.799995,32 16,32 7.1999969,32 0,24.799988 0,16 0,7.1999817 7.1999969,0 16,0z"
              transform="scale(0.75,0.75)"
              fill="white"
            />
          </svg>
          <WarningContainer
            dangerouslySetInnerHTML={{
              __html: msgForSlotExpired || messages.TimeNoLongerAvailable,
            }}
          />
        </WarningDiv>
      )}
      <DescriptionStyle
        dangerouslySetInnerHTML={{
          __html: messages.Date,
        }}
      />
      <BlockStyle mb="16px">
        <Controller
          control={control}
          name="date"
          // @ts-expect-error: not improving the typing of the external library
          rules={dateRules}
          render={({ field: { value } }) => (
            <DatePicker
              callback={handlerDatePicker}
              preSelectedMenuDate={value}
              customInput={<CustomInput />}
              {...datePickerProps}
            />
          )}
          onChange={(_: unknown, e: Event) => e.preventDefault()}
        />
      </BlockStyle>
      <DescriptionStyle
        dangerouslySetInnerHTML={{
          __html: messages.Meal,
        }}
      />
      <BlockStyle mb="16px">
        <Controller
          control={control}
          name="meal"
          // @ts-expect-error: not improving the typing of the external library
          rules={mealRules}
          render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => {
            const Input: React.FC<InputProps<ICustomOption, false, GroupBase<ICustomOption>>> = (
              props,
            ) => {
              return (
                <components.Input {...props} aria-describedby={`${name}-error`} aria-required />
              );
            };
            const valueSelected =
              mealOptions.find((meal) => Number(meal.key) === Number(value)) || null;
            return (
              <>
                <Select
                  options={mealOptions}
                  value={valueSelected}
                  onChange={(newValue) => {
                    reqGetPickUPTimes({
                      mealId: newValue?.key,
                      callback: () => {
                        onChange(newValue?.key);
                        setFocus('meal');
                      },
                    });
                  }}
                  aria-label={messages.Meal}
                  isDisabled={isDisabled}
                  name={name}
                  ref={ref}
                  components={{ Input }}
                />
                <ErrorMessage id={`${name}-error`} error={error as TError} />
              </>
            );
          }}
        />
      </BlockStyle>
      {children}
    </BlockStyle>
  );
};

export default BodyContentForModal;
