import type { TDetail } from './type';

export const DEFAULT_NOTIFICATION_NAME = 'notification';

const NotificationService = {
  on(callback: (detail: TDetail) => void, event = DEFAULT_NOTIFICATION_NAME) {
    // any as a hack for addEventListener
    document.addEventListener(event, (e: any) => callback(e.detail));
  },
  open(detail: TDetail) {
    document.dispatchEvent(new CustomEvent(DEFAULT_NOTIFICATION_NAME, { detail }));
  },
};

export default NotificationService;
