import styled, { css } from 'styled-components';

import type { IDefaultTheme } from '@/shared/themes/types';

export const Required = styled.span.attrs(() => ({
  'aria-hidden': 'true',
}))`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    color: ${theme.defaultPrimaryColor};

    &::before {
      content: '\\2736';
      position: initial;
    }
  `}
`;

export const RequiredField = styled(Required).attrs(() => ({
  'aria-hidden': 'true',
}))`
  &::before {
    position: relative;
    bottom: 3px;
  }
`;
