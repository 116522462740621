import React from 'react';
import { useIntl } from 'react-intl';

import OrderAgainSVG from '../icons/orderAgain.svg';
import { ItemDetails, OrderAgainText, ReorderButton } from '../parts';

interface IProps {
  handlerClick: VoidFunction;
  'data-testid'?: string;
}

export const OrderAgainButton: React.FC<IProps> = ({
  handlerClick,
  'data-testid': dataTestId = 'order-again-button',
}) => {
  const { formatMessage } = useIntl();
  return (
    <ItemDetails className="ItemDetails">
      <ReorderButton onClick={handlerClick} data-testid={dataTestId}>
        <OrderAgainSVG
          className="svg-icon MealCalculatorPlus"
          viewBox="0 0 24 24"
          role="presentation"
          aria-hidden="true"
          focusable="false"
        />
        <OrderAgainText>{formatMessage({ id: 'Global.ProductCard.OrderAgain' })}</OrderAgainText>
      </ReorderButton>
    </ItemDetails>
  );
};
