import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const axiosRequestConfiguration: AxiosRequestConfig = {
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 120000,
};
const instance = axios.create(axiosRequestConfiguration);
const responseBody = <T>(response: AxiosResponse<T>): T => response.data;
const handleError = (error: unknown, method: string): void => {
  if (!(error as AxiosError)?.response?.data) {
    throw new Error(`Error occurred during ${method} request: ${(error as Error).message}`);
  }
};
export const apiClient = {
  get: async <T>(url: string) => {
    try {
      const response = await instance.get<T>(url);
      return responseBody(response);
    } catch (error) {
      handleError(error, 'GET');
      throw error;
    }
  },
  post: async <T, K = object>(url: string, body?: K) => {
    try {
      const response = await instance.post<T, AxiosResponse<T>, K>(url, body);
      return responseBody(response);
    } catch (error) {
      handleError(error, 'POST');
      throw error;
    }
  },
  delete: async <T, K = object>(url: string, data?: K) => {
    try {
      const response = await instance.delete<T, AxiosResponse<T>, K>(url, {
        data,
      });
      return responseBody(response);
    } catch (error) {
      handleError(error, 'DELETE');
      throw error;
    }
  },
};
