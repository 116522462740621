import React from 'react';
import { useIntl } from 'react-intl';

import { parseDate } from '@/shared/utils/parseDate';

import { DateMealSelectorFilterButton, DatePickerButton, DateTextSpan, Svg } from '../parts';

interface IProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  disabled?: boolean;
  customInputRef: React.ForwardedRef<HTMLButtonElement>;
  value?: string;
}
const DPickerButton: React.FC<IProps> = ({ isOpen, setOpen, disabled, customInputRef, value }) => {
  const { formatMessage } = useIntl();
  const messages = {
    datePickerAriaLabel: formatMessage({ id: 'DatePickerAriaLabel' }),
  };

  const handlerClick = (): void => {
    setOpen(!isOpen);
  };

  const date = parseDate(value!);
  return (
    <DatePickerButton
      aria-labelledby="TextDateSpan DateLabel"
      id="aria-date-controller"
      onClick={handlerClick}
      aria-expanded={isOpen}
      aria-label={messages.datePickerAriaLabel}
      disabled={disabled}
      type="button"
      className="DatePickerButton"
      ref={customInputRef}
    >
      <DateTextSpan id="TextDateSpan">{date}</DateTextSpan>
      <DateMealSelectorFilterButton className="DateMealSelectorFilterButton">
        <Svg>
          <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </Svg>
      </DateMealSelectorFilterButton>
    </DatePickerButton>
  );
};

export default DPickerButton;
