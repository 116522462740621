import styled, { css } from 'styled-components';

type TImgComponent = {
  width?: string;
  height?: string;
  margin?: string;
  mobMargin?: string;
  borderRadius?: string;
  maxHeight?: string;
  maxWidth?: string;
};
export const ImgComponent = styled.img`
  ${({
    width = 'auto',
    height = 'auto',
    margin = 'auto',
    mobMargin = '0',
    borderRadius = '',
    maxHeight,
    maxWidth,
  }: TImgComponent) => css`
    display: block;
    width: ${width};
    height: ${height};
    margin: ${margin};
    max-height: ${maxHeight};
    max-width: ${maxWidth};
    border-radius: ${borderRadius};

    @media (max-width: 767px) {
      margin: ${mobMargin};
    }
  `}
`;

export const ItemImg = styled.div`
  max-width: 120px;
  height: inherit;

  img {
    max-width: 120px;
    height: inherit;
  }
`;

export const ItemAllergens = styled.div`
  margin: 0;
  padding: 0;
  height: max-content;
  display: flex;
  gap: 4px;
`;

export const ItemAllergen = styled.div`
  &:last-child {
    margin: 0px;
  }
`;
