import { useIntl } from 'react-intl';

import { DEFAULT_CURRENCY } from '@/shared/constants';

type TTotal = Nullable<number | bigint>;
export const useCurrencyFormmater = (currency = DEFAULT_CURRENCY): ((total: TTotal) => string) => {
  const { formatNumber } = useIntl();
  return (total: TTotal): string => {
    if (typeof total !== 'number') return '';
    return formatNumber(total, { style: 'currency', currency });
  };
};
