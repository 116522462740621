import React from 'react';
import { useIntl } from 'react-intl';

import SvgHeart from '../icons/heart.svg';
import SvgHeartOutline from '../icons/heartOutline.svg';
import { ButtonAddToFav } from '../parts';

interface IProps {
  id: string | null;
  marketingName: Nullable<string>;
  IsUserFavorite: boolean;
  handlerClick: VoidFunction;
  'data-testid'?: string;
  className?: string;
}
const ButtonAddToFavorites: React.FC<IProps> = ({
  id,
  marketingName,
  IsUserFavorite,
  handlerClick,
  'data-testid': dataTestId = 'button-add-to-fav',
  className,
}) => {
  const { formatMessage } = useIntl();

  const messages = {
    markAsFavorite: formatMessage(
      { id: 'Global.ButtonAddToFavorites.AddToFavorites' },
      { 0: marketingName },
    ),
    unMarkAsFavorite: formatMessage(
      { id: 'Global.ButtonAddToFavorites.RemoveFromFavorites' },
      { 0: marketingName },
    ),
    ariaLabel: formatMessage({ id: 'Global.ButtonAddToFavorites.AriaLabel' }),
  };
  const ariaDescription = IsUserFavorite ? messages.unMarkAsFavorite : messages.markAsFavorite;
  const ariaDescribedby = `AriaDescribedbyAddToFavoritesButton_${id}`;
  return (
    <>
      <ButtonAddToFav
        onClick={handlerClick}
        aria-label={messages.ariaLabel}
        aria-describedby={ariaDescribedby}
        data-testid={dataTestId}
        className={`ButtonAddToFav ${className || ''}`}
      >
        {!IsUserFavorite ? (
          <SvgHeartOutline
            data-testid="heart-outline"
            className="svg-icon SvgHeartOutline"
            viewBox="0 0 24 24"
            role="presentation"
            aria-hidden="true"
            focusable="false"
          />
        ) : (
          <SvgHeart
            data-testid="heart"
            className="svg-icon SvgHeart"
            viewBox="0 0 24 24"
            role="presentation"
            aria-hidden="true"
            focusable="false"
          />
        )}
      </ButtonAddToFav>
      <span id={ariaDescribedby} className="sr-only">
        {ariaDescription}
      </span>
    </>
  );
};
export default ButtonAddToFavorites;
