import type { TStyleCustomSelect } from './types';

const getOptionBackgroundColor = (isFocused: boolean, isSelected: boolean): string | undefined => {
  if (isFocused) return '#757575';
  if (isSelected) return 'var(--primary-color, #216ba5)';
  return undefined;
};
export const defaultStyles: TStyleCustomSelect = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: '#efefef',
    width: '100%',
    borderRadius: '22.5px',
    borderColor: '#efefef',
    '&:focus-visible': { borderColor: '#000000' }, // border style on hover
    '&:focus': { outline: '2px solid #000000', boxShadow: '0 0 2px 2px #000000' }, // border style on hover
    '&:hover': { borderColor: '#000000' }, // border style on hover
    '&:active': { outline: '2px solid #000000', boxShadow: '0 0 2px 2px #000000' },
    boxShadow: isFocused ? '0 0 1.6px 1.6px #000000' : '0 0 0 0 #000000',
    border: isFocused ? '0 0 2px 2px #000000' : '0 0 0 0 #000000',
  }),
  menu: (styles) => ({
    ...styles,
  }),
  menuList: (styles) => ({
    ...styles,
    width: '100%',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    width: '56px',
    backgroundColor: '#fff',
    borderTopRightRadius: '22.5px',
    borderBottomRightRadius: '22.5px',
    padding: '0px 0px 0px 8px',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: '14px',
    fontWeight: 'bold',
    paddingLeft: '8px',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: 'var(--primary-color, #216ba5)',
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      width: '100%',
      borderColor: isSelected ? 'var(--primary-color, #216ba5)' : '#efefef',
      backgroundColor: getOptionBackgroundColor(isFocused, isSelected),
      color: isSelected || isFocused ? 'white' : 'black',
      cursor: 'default',
      boxShadow: '0 0 0 0px var(--primary-color, #216ba5)',
      border: isFocused ? '2px solid #000000' : '0px solid #000000',
      fontFamily: 'Open Sans',
      fontSize: '14px',
    };
  },
};
