import React, { FC, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import type { IDefaultTheme } from '@/shared/themes/types';

import type { AnyAction, Store } from '@reduxjs/toolkit';

interface IProps {
  resources: Record<string, string>;
  store?: Store<unknown, AnyAction>;
  theme: IDefaultTheme;
}
const AppProviders: FC<PropsWithChildren<IProps>> = ({ children, resources, store, theme }) => {
  return (
    <IntlProvider messages={resources} locale="en" defaultLocale="en" fallbackOnEmptyString={false}>
      {store ? (
        <ReduxProvider store={store}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ReduxProvider>
      ) : (
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      )}
    </IntlProvider>
  );
};

export default AppProviders;
