export const DEFAULT_MODAL_ARIA_LABELLEDBY = 'modalHeading';

/**
 * Date-fns formats
 * @link https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md
 * @link https://date-fns.org/v2.30.0/docs/format
 */
export enum FORMAT_DATE_TIME {
  MMDDYYYY = 'MM/dd/yyyy', // 05/31/2023
  MMMDDYYYY = 'MMM dd, yyyy', // May 31, 2023
  eeeMMDD = 'eee MM/dd', // 'Wed 05/31'
  MMdd = 'MM/dd', // 05/31
  YYYYMMDDTHHmmss = "yyyy-MM-dd'T'HH:mm:ss", // '2023-05-31T16:16:15'
  AMPM = 'h:mm aa', // '4:16 PM',
  MMMM = 'MMMM', // February
  EEEE = 'EEEE', // Sunday
}

export enum MODE {
  DAILY = 'Daily',
  FOOD_ORDER = 'FoodOrder',
  WEEKLY = 'Weekly',
  CART = 'Cart',
}

export const FULFILLMENT_METHODS = {
  PICK_UP: {
    name: 'Pickup',
    const: 0,
  },
  DELIVERY: {
    name: 'Delivery',
    const: 1,
  },
};

export const Fulfillment = {
  PickUpFulfillment: {
    PickUpDate: '',
    PickUpTime: '',
    PickupDateTime: '',
    RecipientInstructions: '',
    PickupTimeIncrement: null,
    NormalizedMinPickupTime: '',
  },
  DeliveryFulfillment: {
    DeliveryDate: '',
    DeliveryTime: '',
    DeliveryDateTime: '',
    DeliveryLocation: '',
    RecipientInstructions: '',
  },
  DeliveryLocation: '',
  mealType: '0',
  SelectedFulfillmentMethod: FULFILLMENT_METHODS.PICK_UP.name,
  PickUpLocation: '',
  PeriodId: null,
  SelectedDate: '',
};

export type FulfillmentType = {
  PickUpFulfillment: {
    PickUpDate: string;
    PickUpTime: string;
    PickupDateTime: string;
    RecipientInstructions: string;
    PickupTimeIncrement: number | null;
    NormalizedMinPickupTime: string;
  };
  DeliveryFulfillment: {
    DeliveryDate: string;
    DeliveryTime: string;
    DeliveryDateTime: string;
    DeliveryLocation: string;
    RecipientInstructions: string;
  };
  DeliveryLocation: string;
  mealType: string;
  SelectedFulfillmentMethod: string;
  PickUpLocation: string;
  PeriodId: number | null;
  SelectedDate: string | null;
};

export const PREFIX_URL_API = {
  get API() {
    return `/api`;
  },
  get MENU() {
    return `${this.API}/menu`;
  },
  get PRODUCT() {
    return `${this.API}/product`;
  },
  get CHECKOUT() {
    return `${this.API}/checkout`;
  },
  get CART() {
    return `${this.API}/cart`;
  },
  get BASKET() {
    return `${this.API}/basket`;
  },
  get LOYALTY() {
    return `${this.API}/loyalty`;
  },
  get LOCATIONS() {
    return `${this.API}/locations`;
  },
  get MANAGE_USER_FAVORITES() {
    return `${this.API}/info`;
  },
};

export const APP_ELEMENTS = {
  MENUS: 'react_menus',
  CART: 'react_cart',
  CHECKOUT: 'react_checkout',
  CONFIRMATION: 'react_complete',
  MAIL_SUBSCRIPTION: 'react_mail_subscription',
  LOCATIONS: 'react_locations',
  MANAGE_USER_FAVORITES: 'react_manage_user_favorites',
  PRODUCT_DETAILS: 'react_product_details',
};
export const MODAL_ELEMENTS = {
  ROOT_MODAL: 'modal-root',
  MAIL_SUBSCRIPTION_MODAL: 'modal-root-mail-subsription',
  LOCATIONS_MODAL: 'modal-root-locations-modal',
};
export const MODIFIER_TYPES = {
  /// Yes or No type modifer.
  YES_NO: {
    name: 'YesNo',
    const: 0,
  },
  /// Select one option type Modifer.
  SELECT_ONE: {
    name: 'SelectOne',
    const: 1,
  },
  /// Select many options type modifer.
  SELECT_MANY: {
    name: 'SelectMany',
    const: 2,
  },
  /// Date Picker modifier.
  DATE: {
    name: 'Date',
    const: 3,
  },
  /// Text Picker modifier.
  TEXT: {
    name: 'Text',
    const: 4,
  },
};

export const DEFAULT_CURRENCY = 'USD';
