import React from 'react';
import { useIntl } from 'react-intl';

import { Button, ButtonText } from '@/shared/components/Button';
import { ModalOne } from '@/shared/services/modal';
import type { TPortalModalComponent } from '@/shared/services/modal/type';
import { PayPalLaterMessage } from '@/shared/services/paypal';

import ContentOrderSummary from './ContentOrderSummary';
import { SummaryDiv } from './parts';
import type { IBasketProps } from './types';

interface IProps {
  Edit?: VoidFunction;
  Basket?: IBasketProps;
  isShowPayPalMessage?: boolean;
  isPaymentDetailsAvailable: boolean;
}

const OrderSummaryModal: TPortalModalComponent<IProps> = ({
  Edit,
  Basket,
  isShowPayPalMessage = false,
  isPaymentDetailsAvailable,
  close,
}) => {
  const { formatMessage } = useIntl();
  const messages = {
    edit: formatMessage({ id: 'edit' }),
    done: formatMessage({ id: 'done' }),
    summaryTitle: formatMessage({
      id: 'Checkout.OrderSummary.Modal.Title',
    }),
  };

  const footer = (
    <>
      {Edit && (
        <>
          <ButtonText click={Edit} height="initial" bg="initial" padding="1px 6px">
            {messages.edit}
          </ButtonText>

          <Button click={close} isDark>
            <span
              dangerouslySetInnerHTML={{
                __html: messages.done,
              }}
            />
          </Button>
        </>
      )}
      {!Edit && (
        <Button margin="0 0 0 auto;" click={close} isDark>
          <span
            dangerouslySetInnerHTML={{
              __html: messages.done,
            }}
          />
        </Button>
      )}
    </>
  );

  return (
    <ModalOne
      title={messages.summaryTitle}
      handlerClose={close}
      footer={footer}
      isFocusOnCloseButton
    >
      <SummaryDiv>
        {Basket && (
          <ContentOrderSummary {...Basket} isPaymentDetailsAvailable={isPaymentDetailsAvailable} />
        )}
        {isShowPayPalMessage && (
          <PayPalLaterMessage
            data-pp-message
            data-pp-layout="text"
            data-pp-placement="payment"
            data-pp-text-color="black"
            data-pp-logo-type="primary"
            data-pp-logo-position="left"
            data-pp-style-text-align="right"
            data-pp-style-text-size="14"
            data-pp-amount={Basket?.Total}
          />
        )}
      </SummaryDiv>
    </ModalOne>
  );
};

export default OrderSummaryModal;
