import styled, { css } from 'styled-components';

import type { IDefaultTheme } from '@/shared/themes/types';

export const WrapperStickyNav = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 0.7rem;
  margin: 0;
  list-style-type: none;
  text-align: left;
  padding-left: 0;
  overflow: auto hidden;
  white-space: nowrap;
`;

export const ItemStickyNav = styled.button`
  ${({ theme, active }: { theme: IDefaultTheme; active: boolean }) => css`
    display: flex;
    gap: 4px;
    height: 30px;
    background-color: ${active ? theme.defaultPrimaryColor : '#fff'};
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 22.5px;
    border: 2px solid transparent;
    color: ${active ? '#fff' : theme.defaultPrimaryColor};
    cursor: pointer;

    &:hover {
      color: #fff;
      border-color: var(--primary-color, #3a7aaa);
      background-color: ${active ? 'var(--secondary-color, #000000)' : '#fff'};
    }
    &:focus-visible {
      outline: none;
      border-color: #000000;
    }

    & > .svg {
      fill: ${active ? '#fff' : theme.defaultPrimaryColor};
    }
  `}
`;

export const ItemNameStickyNav = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${({ isActive }: { isActive: boolean }) =>
    isActive ? 'inherit' : 'var(--primary-color, #216ba5)'};
`;
