import { isAfter, isSameDay, startOfDay } from 'date-fns/fp';

export const handleAvailableDates = <
  T extends {
    AvailableDatesFoodOrder?: string[];
    AvailableDates?: string[];
  } | null,
>(
  data: T,
): T => {
  const today = startOfDay(new Date());

  const newAvailableDatesFoodOrder = data?.AvailableDatesFoodOrder?.filter((date) => {
    return isAfter(today, new Date(date)) || isSameDay(today, new Date(date));
  });

  const newAvailableDates = data?.AvailableDates?.filter((date) => {
    return isAfter(today, new Date(date)) || isSameDay(today, new Date(date));
  });

  const newData = {
    ...data,
    AvailableDates: newAvailableDates,
    AvailableDatesFoodOrder: newAvailableDatesFoodOrder,
  };

  return newData;
};
