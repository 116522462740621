import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ErrorMessage } from '@/shared/components/ErrorMessage';
import type { TError } from '@/shared/components/ErrorMessage/types';
import { RequiredField } from '@/shared/components/Required';

import { ORDER_AHEAD_RULES } from '../constants';
import { Input, SubHeader, TitleTimes } from '../parts';
import type { TOrderAheadValuesForm } from '../types';
import Loader from './Loader';
import SelectOne from './SelectOne';

interface IProps {
  title: string;
  times: {
    value: string;
    title: string;
  }[];
  textNoTimes: string;
  isSlotExpired?: boolean;
  loading: boolean;
}
export const Times: React.FC<IProps> = ({
  title,
  times,
  textNoTimes,
  isSlotExpired = false,
  loading,
}) => {
  const { control } = useFormContext<TOrderAheadValuesForm>();
  const { formatMessage } = useIntl();

  const messages = {
    SelectNewTime: formatMessage({ id: 'SelectNewTime' }),
  };

  const rules = ORDER_AHEAD_RULES.TIME(title);

  return (
    <Controller
      control={control}
      name="time"
      // @ts-expect-error: not improving the typing of the external library
      rules={rules}
      render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => {
        return (
          <fieldset aria-labelledby="TitleTimes">
            <Input
              tabIndex={-1}
              type="text"
              name={name}
              ref={ref}
              aria-describedby={`${name}-error`}
              aria-required
            />
            <TitleTimes id="TitleTimes">
              <span
                className="legend"
                dangerouslySetInnerHTML={{
                  __html: title.toUpperCase(),
                }}
              />{' '}
              <RequiredField />
            </TitleTimes>
            {isSlotExpired && (
              <SubHeader
                dangerouslySetInnerHTML={{
                  __html: messages.SelectNewTime,
                }}
              />
            )}
            {loading && <Loader />}
            {!loading &&
              (times?.length > 0 ? (
                <SelectOne times={times} selectedTime={value} handlerOnChange={onChange} />
              ) : (
                <div
                  style={{ fontSize: '12px' }}
                  dangerouslySetInnerHTML={{
                    __html: textNoTimes,
                  }}
                />
              ))}
            <ErrorMessage id={`${name}-error`} error={error as TError} />
          </fieldset>
        );
      }}
    />
  );
};
