import React from 'react';
import ReactModal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import type { CSSProperties } from 'styled-components';

import { MODAL_ELEMENTS } from '@/shared/constants';

import HiddenLoadingElement from './HiddenLoadingElement';

interface IProps {
  loading: boolean;
  appElement: string;
  parentSelector?: string;
}

const LoadingBackground: React.FC<IProps> = ({
  loading,
  appElement,
  parentSelector = MODAL_ELEMENTS.ROOT_MODAL,
}) => {
  const cssOverride: CSSProperties = {
    display: 'block',
    margin: '25% auto',
    color: '#fff',
  };

  const defaultStyle: ReactModal.Styles = {
    overlay: {
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      overflow: 'auto',
      inset: '0px',
      zIndex: 999,
    },
    content: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      border: 'none',
      background: 'none',
    },
  };
  const appEl = document.getElementById(appElement)!;
  const getParenSelector = (): HTMLElement => document.getElementById(parentSelector)!;

  return (
    <ReactModal
      isOpen={loading}
      style={defaultStyle}
      appElement={appEl}
      parentSelector={getParenSelector}
      bodyOpenClassName="overflow_hidden"
      htmlOpenClassName="overflow_hidden"
    >
      <ClipLoader color="white" cssOverride={cssOverride} size={50} />
      <HiddenLoadingElement />
    </ReactModal>
  );
};
export default LoadingBackground;
