import React, { useEffect } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import { srSpeak } from '@/shared/utils/srSpeak';

import NotificationService, { DEFAULT_NOTIFICATION_NAME } from '../action';
import { NOTIFICATIONS } from '../constants';

const ErrorText = (text: string): JSX.Element => {
  const errorSvg = (
    <svg className="svg" style={{ top: '3px' }} aria-label="error" role="img" focusable="false">
      <g>
        <path
          transform="rotate(0,10,10) translate(4,4) scale(0.375,0.375)  "
          fill="#FF0000"
          // eslint-disable-next-line max-len
          d="M9.9000245,6.000003L6.0000001,9.8999988 12.100037,16.000007 6.0000001,22.100002 9.9000245,25.999997 16,19.900018 22.100037,25.999997 26,22.100002 19.900024,16.000007 26,9.8999988 22.100037,6.000003 16,12.099997z M16,0C24.799988,0 32,7.2000005 32,16.000007 32,24.800016 24.799988,32.000001 16,32.000001 7.2000123,32.000001 8.3946347E-08,24.800016 0,16.000007 8.3946347E-08,7.2000005 7.2000123,0 16,0z"
        />
      </g>
    </svg>
  );
  return (
    <>
      {errorSvg}{' '}
      <span
        dangerouslySetInnerHTML={{
          __html: text[0].toUpperCase() + text.slice(1),
        }}
      />
    </>
  );
};

const Notification: React.FC = () => {
  useEffect(() => {
    NotificationService.on(
      ({
        status = NOTIFICATIONS.INFO,
        text,
        timeOut = 10000,
        callback = null,
        priority = true,
      }) => {
        NotificationManager[status](ErrorText(text), null, timeOut, callback, priority);
        srSpeak(`Error: ${text}`, 'assertive');
      },
      DEFAULT_NOTIFICATION_NAME,
    );
  }, []);

  return <NotificationContainer css="NotificationCustom" />;
};
export default Notification;
