import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';

import { ItemNameStickyNav, ItemStickyNav, WrapperStickyNav } from './parts';

interface IProps {
  options: {
    id: string;
    name: string;
    active: boolean;
    icon?: ReactElement;
  }[];
  className?: string;
  handleClick: (id: string) => void;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>, id: string) => void;
  handleScroll?: () => Nullable<string>;
}
const StickyNavigation: React.FC<PropsWithChildren<IProps>> = ({
  options,
  className,
  handleClick,
  handleKeyDown,
  handleScroll,
  children,
}) => {
  const parentRef = useRef<HTMLUListElement>(null);
  const [localOptions, setLocalOptions] = useState(options);
  const scrollToCenter = (elementId: string): void => {
    const parent = parentRef.current;
    const buttonElem = document.getElementById(elementId);

    if (parent && buttonElem) {
      const parentComputedStyle = window.getComputedStyle(parent, null);

      const parentBorderLeftWidth = parseInt(
        parentComputedStyle.getPropertyValue('border-left-width'),
        10,
      );

      parent.scrollLeft =
        buttonElem.offsetLeft -
        parent.offsetLeft -
        parent.clientWidth / 2 -
        parentBorderLeftWidth +
        buttonElem.clientWidth / 2;
    }
  };

  useEffect(() => {
    setLocalOptions((prevOptions) =>
      options.map((newOption) => {
        const existingOption = prevOptions.find((prevOption) => prevOption.id === newOption.id);
        if (existingOption) {
          return {
            ...existingOption,
            ...newOption,
          };
        }
        return newOption;
      }),
    );
  }, [options]);

  useEffect(() => {
    if (handleScroll) {
      const handleScrollEvent = (e: Event): void => {
        e.preventDefault();
        const activeStickyId = handleScroll();

        if (activeStickyId) {
          setLocalOptions((opt) =>
            opt.map((item) => ({
              ...item,
              active: item.id === activeStickyId,
            })),
          );
          scrollToCenter(activeStickyId);
        }
      };

      window.addEventListener('scroll', handleScrollEvent);
      return () => {
        window.removeEventListener('scroll', handleScrollEvent);
      };
    }
    return () => {};
  }, [handleScroll]);

  return (
    <div className={`r-flex r-w-full ${className || ''}`}>
      <WrapperStickyNav ref={parentRef} className="sticky-navs">
        {children}
        {localOptions.map((option) => (
          <li key={option.id} id={option.id}>
            <ItemStickyNav
              active={option.active}
              onClick={(e) => {
                e.preventDefault();
                setLocalOptions((opt) =>
                  opt.map((item) => ({
                    ...item,
                    active: item.id === option.id,
                  })),
                );
                scrollToCenter(option.id);
                handleClick(option.id);
              }}
              onKeyDown={(e) => handleKeyDown?.(e, option.id)}
              aria-label={option.name}
              aria-current={option.active}
              className="sticky-nav-button"
            >
              {option.icon}
              <ItemNameStickyNav isActive={option.active}>{option.name}</ItemNameStickyNav>
            </ItemStickyNav>
          </li>
        ))}
      </WrapperStickyNav>
    </div>
  );
};

export default StickyNavigation;
