export const srSpeak = (text: string, priority = 'polite'): void => {
  const el = document.createElement('div') as HTMLDivElement;
  const id = `speak-${new Date().toDateString()}`;
  el.setAttribute('id', id);
  el.setAttribute('aria-live', priority);
  el.classList.add('sr-only');
  document.body.appendChild(el);

  window.setTimeout(() => {
    document.getElementById(id)!.innerHTML = text;
  }, 100);

  window.setTimeout(() => {
    document.body.removeChild(document.getElementById(id)!);
  }, 1000);
};
