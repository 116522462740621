import { addDays, endOfISOWeek, format, isSameDay, startOfISOWeek } from 'date-fns/fp';

import { FORMAT_DATE_TIME } from '@/shared/constants';

const monthArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

type TWeeks = {
  displayName: string;
  startOfWeek: string;
  endOfWeek: string;
}[];
export const getWeeks = (): TWeeks => {
  const date = new Date();
  let startOfWeek = startOfISOWeek(date).toDateString();
  let endOfWeek = endOfISOWeek(date).toDateString();
  const weeks: TWeeks = [];
  for (let i = 0; i < 5; i++) {
    const dateEnd = new Date(endOfWeek).getDate();
    const dateStart = new Date(startOfWeek).getDate();
    const monthEnd = monthArray[new Date(endOfWeek).getMonth()];
    const monthStart = monthArray[new Date(startOfWeek).getMonth()];
    const displayName = `${monthStart} ${dateStart} - ${monthEnd} ${dateEnd}`;

    weeks.push({
      displayName: String(displayName),
      startOfWeek,
      endOfWeek,
    });
    endOfWeek = addDays(7, new Date(endOfWeek)).toDateString();
    startOfWeek = addDays(7, new Date(startOfWeek)).toDateString();
  }

  return weeks;
};

export const parseWeek = (startDate: string, endDate: string): string => {
  if (!startDate || !endDate) return '';

  const weeks = getWeeks();
  const week = weeks.find((w) => w.startOfWeek === startDate);
  if (!week) {
    return '';
  }

  return week.displayName;
};

export const parseDate = (date: Nullable<string>): string => {
  if (!date) return '';

  const dateData = new Date(date);

  const isEqualDates = isSameDay(new Date(), dateData);
  return isEqualDates ? 'Today' : format(FORMAT_DATE_TIME.eeeMMDD, dateData);
};
